import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { FreeformComponent } from '../freeform/freeform.component';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-app-gallery',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './app-gallery.component.html',
  styleUrl: './app-gallery.component.css',
})
export class AppGalleryComponent implements OnInit {
  appLink = '';
  applications: any[] = [];
  appTypes: any[] = [];
  activeProduct: number = -1;
  isSubCategoryExpanded: boolean[] = [];

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title, // Inject Title service for setting title
    private metaService: Meta // Inject Meta service for setting meta tags,
  ) {
    const appLink = this.route.snapshot.paramMap.get('appLink');
    this.appLink = appLink || '';
    if (this.appLink) {
      this.get_application();
      this.getGallery(this.appLink);
    } else {
      this.get_application();
      this.getGallery(this.appLink);
    }
  }

  ngOnInit(): void {}

  get_application() {
    this.httpService.GetAPI('application/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.appTypes = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getGallery(appLink: string) {
    const data = { link: appLink };
    this.httpService
      .PostAPI('productGallery/getApplicationById', data)
      .subscribe(
        (resdata: any) => {
          if (resdata.isSuccess) {
            this.applications = resdata.data;
            this.isSubCategoryExpanded = new Array(
              this.applications.length
            ).fill(false);
          }
        },
        (err) => {
          this.router.navigateByUrl('/redirect-page');
        }
      );
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  toggleSubCategoryReadMore(index: number) {
    this.isSubCategoryExpanded[index] = !this.isSubCategoryExpanded[index];
  }

  showReadMoreButton(description: string): any {
    return description && description.length > 375;
  }

  setActiveProduct(index: number): void {
    this.activeProduct = index;
  }

  prevImage(): void {
    this.activeProduct =
      this.activeProduct > 0
        ? this.activeProduct - 1
        : this.applications.length - 1;
  }

  nextImage(): void {
    this.activeProduct =
      this.activeProduct < this.applications.length - 1
        ? this.activeProduct + 1
        : 0;
  }
}
