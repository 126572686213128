import { Component, HostListener, Renderer2 } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterOutlet],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  logo = '';
  title = '';
  phones: any[] = [];
  email = '';
  address = '';
  fbLink = '';
  instaLink = '';
  linkedinLink = '';
  youtubeLink = '';
  menus: any[] = [];
  products: any[] = [];
  moreInfo: any[] = [];
  abouts: any[] = [];
  isVisible: boolean = false;

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getSettings();
    this.getMenu();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    // Show or hide the button based on the scroll position
    this.isVisible = window.pageYOffset > 200;
  }

  // Smooth scroll to the top
  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // Add class after scrolling is complete
    setTimeout(() => {
      const topElement = document.querySelector('.link-to-top');
      if (topElement) {
        this.renderer.addClass(topElement, 'scroll-complete');
      }
    }, 1000); // Adjust timeout based on scroll duration
  }

  getSettings(): void {
    this.httpService.GetAPI('websiteSettings/getSettings').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.logo = resdata.data.mobileFile;
          this.title = resdata.data.title;
          const phoneString = resdata.data.phone;
          this.phones = phoneString
            .split('|')
            .map((phone: any) => phone.trim());
          this.email = resdata.data.email;
          this.address = resdata.data.address;
          this.fbLink = resdata.data.fbLink;
          this.instaLink = resdata.data.instaLink;
          this.linkedinLink = resdata.data.linkedinLink;
          this.youtubeLink = resdata.data.youtubeLink;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getMenu(): void {
    this.httpService.GetAPI('menu/getFooterMenu').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.menus = resdata.data.filter(
            (item: any) =>
              item.menuName === 'About' || item.menuName === 'Contact'
          );
          this.products = resdata.data.filter(
            (item: any) => item.menuTemplate === 'Product'
          );
          this.moreInfo = resdata.data.filter(
            (item: any) =>
              item.menuTemplate === 'Project' ||
              item.menuTemplate === 'Gallery' ||
              item.menuTemplate === 'CMS'
          );
          this.abouts = resdata.data.filter(
            (item: any) => item.menuName === 'Resources'
          );
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }
}
