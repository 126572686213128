import { Component } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { FooterComponent } from '../footer/footer.component';
import { FreeformComponent } from '../freeform/freeform.component';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [
    LoaderComponent,
    FooterComponent,
    FreeformComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule,
    RouterLink,
    RouterOutlet,
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.css',
})
export class ProjectsComponent {
  projectTypes: any[] = [];
  projects: any[] = [];
  //clients: any[] = [];

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.getProjectType();
    this.getProjects();
    //this.getClient();
  }

  getProjectType() {
    this.httpService.GetAPI('projectType/getAllProjectType').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.projectTypes = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getProjects() {
    this.httpService.GetAPI('project/getAllProject').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.projects = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  // getClient() {
  //   this.httpService.GetAPI('client/getAllClient').subscribe(
  //     (resdata: any) => {
  //       if (resdata.isSuccess) {
  //         this.clients = resdata.data;
  //       }
  //     },
  //     (err) => {
  //       this.toastr.error(err.error.message);
  //     }
  //   );
  // }
}
