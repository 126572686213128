<section #scrollTarget>
  <div
    class="page-section z-index-1 bg-gray-light-1 pb-4"
    *ngIf="category.categoryName"
  >
    <div class="container position-relative">
      <div class="row position-relative">
        <div class="col-lg-7">
          <img [src]="this.httpService.fileUrl(category.bannerFile)" />
        </div>
        <div class="col-lg-5">
          <div>
            <h2 class="hs-title-2 mb-20 text-dark mt-4">
              {{ category.categoryName }}
            </h2>
            <div
              class="product-desc text-gray justify"
              [innerHtml]="sanitizeHtml(categoryDescription)"
            ></div>
            <button
              *ngIf="showReadMoreButton"
              class="btn-read-more"
              (click)="toggleReadMore()"
            >
              {{ isExpanded ? "Read Less" : "Read More" }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="row position-relative mt-40 wow fadeInUp"
        data-wow-delay="0.1s"
        *ngIf="
          category.productSubCategories &&
          category.productSubCategories.length > 0
        "
      >
        <ng-container
          *ngFor="
            let subCategory of category.productSubCategories;
            let i = index
          "
        >
          <div
            class="col-6 col-sm-6 col-lg-3 mb-20 product-menu-hover local-scroll"
          >
            <a
              [href]="
                'products/' +
                productLink +
                '/' +
                category.categoryLink +
                '/' +
                subCategory.subCategoryLink
              "
              class="team-item pt-3 pb-3"
            >
              <div class="team-item-image">
                <img
                  *ngIf="subCategory.desktopFile"
                  [src]="this.httpService.fileUrl(subCategory.desktopFile)"
                  [alt]="
                    subCategory.imageTitle
                      ? subCategory.imageTitle
                      : subCategory.subCategoryName
                  "
                />
                <span class="product-new-tag" *ngIf="subCategory.isNewTag"
                  >NEW</span
                >
              </div>
              <div class="team-item-descr text-start">
                <div class="team-item-name" *ngIf="subCategory.subCategoryName">
                  {{ subCategory.subCategoryName }}
                </div>
                <div class="team-item-role" *ngIf="category.categoryName">
                  {{ category.categoryName }}
                </div>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<section #scrollTarget1>
  <div
    class="page-section pd-gallery-box pt-md-40"
    *ngIf="subCategory.subCategoryName"
  >
    <div class="container">
      <div class="row position-relative mb-4">
        <div class="col-lg-10">
          <div>
            <h2
              class="hs-title-3 text-dark"
              *ngIf="subCategory.subCategoryName"
            >
              {{ subCategory.subCategoryName }}
            </h2>
            <div
              class="text-gray justify mb-0"
              [innerHtml]="sanitizeHtml(subCategoryDescription)"
            ></div>
            <button
              *ngIf="showSubCategoryReadMoreButton"
              class="btn-read-more"
              (click)="toggleSubCategoryReadMore()"
            >
              {{ isSubCategoryExpanded ? "Read Less" : "Read More" }}
            </button>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="pro-thumb">
            <img
              *ngIf="subCategory.sampleFile"
              [src]="this.httpService.fileUrl(subCategory.sampleFile)"
              class="w-100"
            />
          </div>
        </div>
      </div>
      <div
        class="row gx-no gy-no wow fadeInUp"
        data-wow-delay="0"
        *ngIf="
          subCategory.productGallery && subCategory.productGallery.length > 0
        "
      >
        <div class="col-lg-8">
          <div class="work-img g-mb-1">
            <div class="work-img-bg post-prev-img"></div>
            <a
              [href]="
                this.httpService.fileUrl(
                  subCategory.productGallery[0]?.desktopFile
                )
              "
              class="lightbox mfp-image"
              title="{{ subCategory.productGallery[0]?.title }}"
            >
              <img
                [src]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[0]?.desktopFile
                  )
                "
                alt="{{ subCategory.productGallery[0]?.title || 'Gallery' }}"
              />
            </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            *ngIf="subCategory.productGallery.length > 1"
            class="work-img g-mb-1"
          >
            <div class="work-img-bg post-prev-img"></div>
            <a
              [href]="
                this.httpService.fileUrl(
                  subCategory.productGallery[1]?.desktopFile
                )
              "
              class="lightbox mfp-image"
              title="{{ subCategory.productGallery[1]?.title }}"
            >
              <img
                [src]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[1]?.desktopFile
                  )
                "
                alt="{{ subCategory.productGallery[1]?.title }}"
              />
            </a>
          </div>
          <div
            *ngIf="subCategory.productGallery.length > 2"
            class="work-img g-mb-1"
          >
            <div class="work-img-bg post-prev-img"></div>
            <a
              [href]="
                this.httpService.fileUrl(
                  subCategory.productGallery[2]?.desktopFile
                )
              "
              class="lightbox mfp-image"
              title="{{ subCategory.productGallery[2]?.title }}"
            >
              <img
                [src]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[2]?.desktopFile
                  )
                "
                alt="{{ subCategory.productGallery[2]?.title }}"
              />
            </a>
          </div>
        </div>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 3
          "
        >
          <div
            class="col-lg-4"
            *ngFor="
              let gallery of subCategory.productGallery.slice(3, 6);
              let i = index
            "
          >
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="this.httpService.fileUrl(gallery.desktopFile)"
                class="lightbox mfp-image"
                title="{{ gallery.title || 'Gallery' }}"
              >
                <img
                  [src]="this.httpService.fileUrl(gallery.desktopFile)"
                  alt="{{ gallery.title || 'Gallery' }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 6
          "
        >
          <div class="col-lg-4">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[6]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[6]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[6]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[6]?.title }}"
                />
              </a>
            </div>
            <div
              *ngIf="subCategory.productGallery.length > 7"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[7]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[7]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[7]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[7]?.title }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="subCategory.productGallery.length > 8">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[8]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[8]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[8]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[8]?.title }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 9
          "
        >
          <div
            class="col-lg-4"
            *ngFor="
              let gallery of subCategory.productGallery.slice(9, 12);
              let i = index
            "
          >
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="this.httpService.fileUrl(gallery.desktopFile)"
                class="lightbox mfp-image"
                title="{{ gallery.title || 'Gallery' }}"
              >
                <img
                  [src]="this.httpService.fileUrl(gallery.desktopFile)"
                  alt="{{ gallery.title || 'Gallery' }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 12
          "
        >
          <div class="col-lg-8" *ngIf="subCategory.productGallery.length > 12">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[12]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[12]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[12]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[12]?.title }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              *ngIf="subCategory.productGallery.length > 13"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[13]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[13]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[13]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[13]?.title }}"
                />
              </a>
            </div>
            <div
              *ngIf="subCategory.productGallery.length > 14"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[14]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[14]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[14]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[14]?.title }}"
                />
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 15
          "
        >
          <div
            class="col-lg-4"
            *ngFor="
              let gallery of subCategory.productGallery.slice(15, 18);
              let i = index
            "
          >
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="this.httpService.fileUrl(gallery.desktopFile)"
                class="lightbox mfp-image"
                title="{{ gallery.title || 'Gallery' }}"
              >
                <img
                  [src]="this.httpService.fileUrl(gallery.desktopFile)"
                  alt="{{ gallery.title || 'Gallery' }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 18
          "
        >
          <div class="col-lg-4">
            <div
              *ngIf="subCategory.productGallery.length > 18"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[18]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[18]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[18]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[18]?.title }}"
                />
              </a>
            </div>
            <div
              *ngIf="subCategory.productGallery.length > 19"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[19]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[19]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[19]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[19]?.title }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="subCategory.productGallery.length > 20">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[20]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[20]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[20]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[20]?.title }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 21
          "
        >
          <div
            class="col-lg-4"
            *ngFor="
              let gallery of subCategory.productGallery.slice(21, 24);
              let i = index
            "
          >
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="this.httpService.fileUrl(gallery.desktopFile)"
                class="lightbox mfp-image"
                title="{{ gallery.title || 'Gallery' }}"
              >
                <img
                  [src]="this.httpService.fileUrl(gallery.desktopFile)"
                  alt="{{ gallery.title || 'Gallery' }}"
                />
              </a>
            </div></div
        ></ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 24
          "
        >
          <div class="col-lg-8">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[24]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[24]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[24]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[24]?.title }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-4">
            <div
              *ngIf="subCategory.productGallery.length > 25"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[25]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[25]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[25]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[25]?.title }}"
                />
              </a>
            </div>
            <div
              *ngIf="subCategory.productGallery.length > 26"
              class="work-img g-mb-1"
            >
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(
                    subCategory.productGallery[26]?.desktopFile
                  )
                "
                class="lightbox mfp-image"
                title="{{ subCategory.productGallery[26]?.title }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(
                      subCategory.productGallery[26]?.desktopFile
                    )
                  "
                  alt="{{ subCategory.productGallery[26]?.title }}"
                />
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            subCategory.productGallery && subCategory.productGallery.length > 27
          "
        >
          <div
            class="col-lg-4"
            *ngFor="
              let gallery of subCategory.productGallery.slice(27, 30);
              let i = index
            "
          >
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="this.httpService.fileUrl(gallery.desktopFile)"
                class="lightbox mfp-image"
                title="{{ gallery.title || 'Gallery' }}"
              >
                <img
                  [src]="this.httpService.fileUrl(gallery.desktopFile)"
                  alt="{{ gallery.title || 'Gallery' }}"
                />
              </a>
            </div></div
        ></ng-container>
      </div>
      <div
        class="row wow fadeInUp"
        data-wow-delay="0"
        *ngIf="subCategory.contents && subCategory.contents.length > 0"
      >
        <div class="col-lg-6">
          <dl class="toggle mb-0">
            <ng-container
              *ngFor="let content of subCategory.contents; let i = index"
            >
              <ng-container *ngIf="i % 2 === 0">
                <dt>
                  <a href="javascript:void(0)">{{ content.title }}</a>
                </dt>
                <dd>
                  <div class="py-3">
                    <div
                      class="content-desc"
                      [innerHtml]="sanitizeHtml(content.description)"
                    ></div>
                  </div></dd></ng-container
            ></ng-container>
          </dl>
        </div>
        <div class="col-lg-6">
          <dl class="toggle">
            <ng-container
              *ngFor="let content of subCategory.contents; let i = index"
            >
              <ng-container *ngIf="i % 2 != 0">
                <dt>
                  <a href="javascript:void(0)">{{ content.title }}</a>
                </dt>
                <dd>
                  <div class="py-3">
                    <div
                      class="content-desc"
                      [innerHtml]="sanitizeHtml(content.description)"
                    ></div>
                  </div></dd></ng-container
            ></ng-container>
          </dl>
        </div>
      </div>
    </div>
  </div>
</section>

<router-outlet></router-outlet>
