import { Component } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { FooterComponent } from '../footer/footer.component';
import { FreeformComponent } from '../freeform/freeform.component';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    FooterComponent,
    FreeformComponent,
    HeaderComponent,
    CommonModule,
    ToastrModule,
  ],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.css',
})
export class PageNotFoundComponent {}
