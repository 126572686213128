import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCustomersByType',
  standalone: true,
})
export class FilterCustomersByTypePipe implements PipeTransform {
  transform(customers: any[], customerType: string): any[] {
    if (!customers || !customerType) {
      return [];
    }

    // Filter projects based on the project type
    return customers.filter(
      (customer) => customer.customerTypeId.customerType === customerType
    );
  }
}
