<!-- Page Wrap -->
<div class="page" id="top">
  <app-header></app-header>

  <main id="main">
    <section class="breadcrumb-w">
      <div class="full-wrapper">
        <ul class="breadcrumb letters-spacing mb-0 mx-0">
          <li><a href="/">Home</a></li>
          <li><a href="#products">Products</a></li>
          <li *ngIf="product.productName">
            <a [href]="'products/' + productLink">{{ product.productName }}</a>
          </li>
          <li *ngIf="categoryData?.categoryName">
            <a
              [href]="
                'products/' + productLink + '/' + categoryData?.categoryLink
              "
              >{{ categoryData?.categoryName }}</a
            >
          </li>
          <li *ngIf="subCategoryData?.subCategoryName">
            {{ subCategoryData?.subCategoryName }}
          </li>
        </ul>
      </div>
    </section>

    <!-- Product Section -->
    <section #scrollTarget>
      <div class="page-section pb-0 pt-50 pt-md-0" *ngIf="product.productName">
        <div class="container position-relative">
          <div class="row gx-5 reverse-div">
            <div class="col-lg-5">
              <div class="text-gray">
                <h1 class="hs-title-2 mt-4 text-dark">
                  {{ product.productName }}
                </h1>
                <div
                  class="product-desc justify"
                  [innerHtml]="sanitizeHtml(productDescription)"
                ></div>
                <button
                  *ngIf="showReadMoreButton"
                  class="btn-read-more"
                  (click)="toggleReadMore()"
                >
                  {{ isExpanded ? "Read Less" : "Read More" }}
                </button>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="mb-30 mb-sm-0 mt-md-30 wow fadeInUp">
                <img
                  [src]="this.httpService.fileUrl(product.bannerFile)"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="row position-relative wow fadeInUp" data-wow-delay="0.1s">
            <!-- product list -->
            <ng-container *ngIf="productCategories.length > 0">
              <ng-container
                *ngFor="let category of productCategories; let i = index"
              >
                <div
                  class="col-6 col-sm-6 col-lg-3 mb-20 product-menu-hover local-scroll"
                  (click)="setActiveCategory(category._id)"
                  [ngClass]="{
                    active: category._id === activeCategory
                  }"
                >
                  <a
                    [href]="
                      'products/' + productLink + '/' + category.categoryLink
                    "
                    class="team-item pt-3 pb-3"
                  >
                    <div class="team-item-image">
                      <img
                        [src]="this.httpService.fileUrl(category.desktopFile)"
                        [alt]="
                          category.imageTitle
                            ? category.imageTitle
                            : category.categoryName
                        "
                      />
                      <span class="product-new-tag" *ngIf="category.isNewTag"
                        >NEW</span
                      >
                    </div>
                    <div class="team-item-descr text-start">
                      <div class="team-item-name">
                        {{ category.categoryName }}
                      </div>
                      <div class="team-item-role">
                        {{ product.productName }}
                      </div>
                    </div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="directSubCategories.length > 0">
              <ng-container
                *ngFor="let subCategory of directSubCategories; let i = index"
              >
                <div
                  class="col-6 col-sm-6 col-lg-3 mb-20 product-menu-hover local-scroll"
                  (click)="setActiveSubCategory(subCategory._id)"
                  [ngClass]="{
                    active: subCategory._id === activeSubCategory
                  }"
                >
                  <a
                    [href]="
                      'products/' +
                      productLink +
                      '/' +
                      subCategory.subCategoryLink
                    "
                    class="team-item pt-3 pb-3"
                  >
                    <div class="team-item-image">
                      <img
                        [src]="
                          this.httpService.fileUrl(subCategory.desktopFile)
                        "
                        [alt]="
                          subCategory.imageTitle
                            ? subCategory.imageTitle
                            : subCategory.subCategoryName
                        "
                      />
                      <span class="product-new-tag" *ngIf="subCategory.isNewTag"
                        >NEW</span
                      >
                    </div>
                    <div class="team-item-descr text-start">
                      <div class="team-item-name">
                        {{ subCategory.subCategoryName }}
                      </div>
                      <div class="team-item-role">
                        {{ product.productName }}
                      </div>
                    </div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <!-- End Team item -->
          </div>
        </div>
      </div>
    </section>
    <!-- Product Section Ends-->

    <router-outlet></router-outlet>

    <!-- <ng-container *ngIf="productCategories.length > 0">
      <ng-container *ngFor="let category of productCategories">
        <section
          [id]="category.categoryLink"
          [ngClass]="
            category._id === activeCategory
              ? 'page-section z-index-1 bg-gray-light-1 pb-4 categories v-show'
              : 'v-hidden'
          "
        >
          <div
            class="container position-relative"
            [ngStyle]="{
              display: category._id === activeCategory ? 'block' : 'none'
            }"
          >
            <div class="row position-relative">
              <div class="col-lg-7">
                <img [src]="this.httpService.fileUrl(category.bannerFile)" />
              </div>
              <div class="col-lg-5">
                <div>
                  <h2 class="hs-title-2 mb-20 text-dark mt-4">
                    {{ category.categoryName }}
                  </h2>
                  <p
                    class="text-gray justify mb-0"
                    *ngIf="category.description"
                  >
                    {{ category.description }}
                  </p>
                  
                </div>
              </div>
            </div>
            <div
              class="row position-relative mt-40 wow fadeInUp"
              data-wow-delay="0.1s"
              *ngIf="
                category.productSubCategories &&
                category.productSubCategories.length > 0
              "
            >
              <ng-container
                *ngFor="
                  let subCategory of category.productSubCategories;
                  let i = index
                "
              >
                <div
                  class="col-6 col-sm-6 col-lg-3 mb-20 product-menu-hover local-scroll"
                  (click)="setActiveSubCategory(subCategory._id)"
                  [ngClass]="{
                    active: subCategory._id === activeSubCategory
                  }"
                >
                  <a
                    [href]="
                      'products/' +
                      productLink +
                      '#' +
                      subCategory.subCategoryLink
                    "
                    class="team-item pt-3 pb-3"
                  >
                    <div class="team-item-image">
                      <img
                        *ngIf="subCategory.desktopFile"
                        [src]="
                          this.httpService.fileUrl(subCategory.desktopFile)
                        "
                        alt="Image Description"
                      />
                    </div>
                    <div class="team-item-descr text-start">
                      <div
                        class="team-item-name"
                        *ngIf="subCategory.subCategoryName"
                      >
                        {{ subCategory.subCategoryName }}
                      </div>
                      <div class="team-item-role" *ngIf="category.categoryName">
                        {{ category.categoryName }}
                      </div>
                    </div>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </section>
        <ng-container *ngFor="let subCategory of category.productSubCategories">
          <section
            [id]="subCategory.subCategoryLink"
            [ngClass]="
              subCategory._id === activeSubCategory
                ? 'page-section pd-gallery-box pt-md-40 v-show'
                : 'v-hidden'
            "
          >
            <div
              class="container"
              [ngStyle]="{
                display:
                  subCategory._id === activeSubCategory ? 'block' : 'none'
              }"
            >
              <div class="row position-relative mb-4">
                <div class="col-lg-10">
                  <div class="wow linesAnimIn" data-splitting="lines">
                    <h2
                      class="hs-title-3 text-dark"
                      *ngIf="subCategory.subCategoryName"
                    >
                      {{ subCategory.subCategoryName }}
                    </h2>
                    <p
                      class="text-gray justify mb-0"
                      *ngIf="subCategory.description"
                    >
                      {{ subCategory.description }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="pro-thumb wow linesAnimIn" data-splitting="lines">
                    <img
                      *ngIf="subCategory.sampleFile"
                      [src]="this.httpService.fileUrl(subCategory.sampleFile)"
                      class="w-100"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row gx-no gy-no wow fadeInUp"
                data-wow-delay="0"
                *ngIf="subCategory.productGallery.length > 0"
              >
                <div class="col-lg-8">
                  <div class="work-img g-mb-1">
                    <div class="work-img-bg post-prev-img"></div>
                    <a
                      [href]="
                        this.httpService.fileUrl(
                          subCategory.productGallery[0]?.desktopFile
                        )
                      "
                      class="lightbox mfp-image"
                      title="{{ subCategory.productGallery[0]?.title }}"
                    >
                      <img
                        [src]="
                          this.httpService.fileUrl(
                            subCategory.productGallery[0]?.desktopFile
                          )
                        "
                        alt="{{
                          subCategory.productGallery[0]?.title || 'Gallery'
                        }}"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div
                    *ngIf="subCategory.productGallery.length > 1"
                    class="work-img g-mb-1"
                  >
                    <div class="work-img-bg post-prev-img"></div>
                    <a
                      [href]="
                        this.httpService.fileUrl(
                          subCategory.productGallery[1]?.desktopFile
                        )
                      "
                      class="lightbox mfp-image"
                      title="{{ subCategory.productGallery[1]?.title }}"
                    >
                      <img
                        [src]="
                          this.httpService.fileUrl(
                            subCategory.productGallery[1]?.desktopFile
                          )
                        "
                        alt="{{
                          subCategory.productGallery[1]?.title || 'Gallery'
                        }}"
                      />
                    </a>
                  </div>
                  <div
                    *ngIf="subCategory.productGallery.length > 2"
                    class="work-img g-mb-1"
                  >
                    <div class="work-img-bg post-prev-img"></div>
                    <a
                      [href]="
                        this.httpService.fileUrl(
                          subCategory.productGallery[2]?.desktopFile
                        )
                      "
                      class="lightbox mfp-image"
                      title="{{ subCategory.productGallery[2]?.title }}"
                    >
                      <img
                        [src]="
                          this.httpService.fileUrl(
                            subCategory.productGallery[2]?.desktopFile
                          )
                        "
                        alt="{{
                          subCategory.productGallery[2]?.title || 'Gallery'
                        }}"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                *ngIf="subCategory.productGallery.length > 3"
                class="row gx-no wow fadeInUp"
                data-wow-delay="0"
              >
                <div
                  class="col-lg-4"
                  *ngFor="
                    let gallery of subCategory.productGallery.slice(3);
                    let i = index
                  "
                >
                  <div class="work-img g-mb-1">
                    <div class="work-img-bg post-prev-img"></div>
                    <a
                      [href]="this.httpService.fileUrl(gallery.desktopFile)"
                      class="lightbox mfp-image"
                      [title]="gallery.title"
                    >
                      <img
                        [src]="this.httpService.fileUrl(gallery.desktopFile)"
                        alt="{{ gallery.title || 'Gallery' }}"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="row wow fadeInUp"
                data-wow-delay="0"
                *ngIf="subCategory.contents.length > 0"
              >
                <div class="col-lg-6">
                  <dl class="toggle mb-0">
                    <ng-container
                      *ngFor="
                        let content of subCategory.contents;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="i % 2 === 0">
                        <dt>
                          <a href="javascript:void(0)">{{ content.title }}</a>
                        </dt>
                        <dd>
                          <div class="py-3">
                            <div class="content-desc" [innerHtml]="content.description"></div>
                          </div></dd></ng-container
                    ></ng-container>
                  </dl>
                </div>
                <div class="col-lg-6">
                  <dl class="toggle">
                    <ng-container
                      *ngFor="
                        let content of subCategory.contents;
                        let i = index
                      "
                    >
                      <ng-container *ngIf="i % 2 != 0">
                        <dt>
                          <a href="javascript:void(0)">{{ content.title }}</a>
                        </dt>
                        <dd>
                          <div class="py-3">
                            <div class="content-desc" [innerHtml]="content.description"></div>
                          </div></dd></ng-container
                    ></ng-container>
                  </dl>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </ng-container>
    </ng-container> -->
  </main>

  <app-footer></app-footer>
</div>
<!-- End Page Wrap -->
