import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();

  updateData(name: string, data: any) {
    const currentValue = this.dataSource.value;
    // Update the object by setting the new data under the specified key (name)
    const updatedValue = { ...currentValue, [name]: data };
    this.dataSource.next(updatedValue); // Emit the updated object
  }
}
