<div class="page" id="top">
  <app-header></app-header>
  <main id="main">
    <section class="breadcrumb-w">
      <div class="full-wrapper">
        <ul class="breadcrumb letters-spacing mb-0 mx-0">
          <li><a href="/">Home</a></li>
          <li>Gallery</li>
        </ul>
      </div>
    </section>

    <!-- Project Section -->
    <section class="page-section home-projects">
      <div class="container overflow-hidden">
        <div class="row position-relative">
          <div class="wow charsAnimIn text-black text-left mb-20">
            <h1 class="hs-title-1 text-dark-gray">Gallery</h1>
            <p class="hs-title-6 text-gray mb-2">
              Your applications, our solutions
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Nav Tabs -->
            <div class="wow fadeInLeft">
              <ul class="nav nav-tabs gallery-tabs tpl-tabs tab-left animate">
                <li
                  class="nav-item"
                  *ngFor="let type of appTypes; let i = index"
                >
                  <a
                    [href]="'gallery/' + type.link"
                    class="nav-link"
                    [ngClass]="{
                      active: type.link === appLink
                    }"
                    role="button"
                    aria-selected="false"
                  >
                    {{ type.title }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- End Nav Tabs -->

            <!-- Tab panes -->
            <div class="tab-content mt-70 tpl-minimal-tabs-cont wow fadeInUp">
              <div class="tab-pane fade active show" role="tabpanel">
                <div
                  class="row position-relative mt-n40 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <ng-container *ngFor="let app of applications; let i = index">
                    <div class="col-6 col-sm-6 col-lg-3 mb-20 product-item">
                      <div
                        class="product-menu-hover no-pm gallery-scroll"
                        [ngClass]="{ active: i === activeProduct }"
                      >
                        <a
                          href="javascript:void(0)"
                          class="team-item pt-3 pb-3"
                          (click)="setActiveProduct(i)"
                        >
                          <div class="team-item-image">
                            <img
                              [src]="httpService.fileUrl(app.desktopFile)"
                              [alt]="app.title"
                              loading="lazy"
                            />
                          </div>
                          <div class="team-item-descr text-start">
                            <div class="team-item-name">
                              {{ app.subCategoryId?.subCategoryName }}
                            </div>
                            <div
                              *ngIf="
                                app?.categoryId?.categoryName;
                                else productNameBlock
                              "
                            >
                              <div class="team-item-role">
                                {{ app?.categoryId?.categoryName }}
                              </div>
                            </div>
                            <ng-template #productNameBlock>
                              <div
                                class="team-item-role"
                                *ngIf="app?.productId?.productName"
                              >
                                {{ app?.productId?.productName }}
                              </div>
                            </ng-template>
                          </div>
                        </a>
                      </div>

                      <div
                        class="col-lg-12 full-gallery-view"
                        [ngStyle]="{
                          display: i === activeProduct ? 'block' : 'none'
                        }"
                      >
                        <div class="row position-relative mb-4">
                          <div class="col-lg-10 col-md-8">
                            <h2 class="hs-title-3 text-dark">
                              {{ app.subCategoryId?.subCategoryName }}
                            </h2>
                            <div
                              class="product-desc text-gray justify"
                              *ngIf="!isSubCategoryExpanded[i]"
                              [innerHtml]="
                                app.subCategoryId?.description | slice : 0 : 375
                              "
                            ></div>
                            <div
                              class="product-desc text-gray justify"
                              *ngIf="isSubCategoryExpanded[i]"
                              [innerHtml]="
                                sanitizeHtml(app.subCategoryId?.description)
                              "
                            ></div>
                            <button
                              *ngIf="
                                showReadMoreButton(
                                  app.subCategoryId?.description
                                )
                              "
                              class="btn-read-more"
                              (click)="toggleSubCategoryReadMore(i)"
                            >
                              {{
                                isSubCategoryExpanded[i]
                                  ? "Read Less"
                                  : "Read More"
                              }}
                            </button>
                          </div>
                          <div class="col-lg-2 col-md-4">
                            <img
                              *ngIf="app.subCategoryId?.sampleFile"
                              [src]="
                                httpService.fileUrl(
                                  app.subCategoryId?.sampleFile
                                )
                              "
                              loading="lazy"
                              class="w-100"
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-9 position-relative next-prv-over">
                            <div class="prv-next-button-w">
                              <div
                                (click)="prevImage()"
                                class="btn btn-light prev-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="27px"
                                  height="57px"
                                  viewBox="0 0 27 57"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  focusable="false"
                                >
                                  <path
                                    d="M5.005,28.500 L27.000,54.494 L24.000,56.994 L0.005,28.500 L24.000,0.006 L27.000,2.506 L5.005,28.500 Z"
                                  ></path>
                                </svg>
                              </div>
                              <div
                                (click)="nextImage()"
                                class="btn btn-light next-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlns:xlink="http://www.w3.org/1999/xlink"
                                  width="27px"
                                  height="57px"
                                  viewBox="0 0 27 57"
                                  fill="currentColor"
                                  aria-hidden="true"
                                  focusable="false"
                                >
                                  <path
                                    d="M21.995,28.500 L-0.000,54.494 L3.000,56.994 L26.995,28.500 L3.000,0.006 L-0.000,2.506 L21.995,28.500 Z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div class="position-relative mb-20 pd-gallery-box">
                              <div class="pd-slider bg-dark light-content">
                                <section
                                  class="home-section bg-scroll bg-dark-alpha-60 light-content"
                                >
                                  <div class="work-img-bg post-prev-img">
                                    <a
                                      [href]="
                                        httpService.fileUrl(app.desktopFile)
                                      "
                                      class="lightbox mfp-image"
                                      [title]="app?.title"
                                    >
                                      <img
                                        class="w-100"
                                        [src]="
                                          httpService.fileUrl(app.desktopFile)
                                        "
                                        loading="lazy"
                                      />
                                    </a>
                                  </div>
                                  <div class="">
                                    <div
                                      class="col-md-10 w-100 product-details-text-overlay"
                                    >
                                      <p class="pd-text mb-0">
                                        {{ app?.title }}
                                      </p>
                                    </div>
                                  </div>
                                </section>
                              </div>
                              <div
                                class="slider-counter slider-counting-product-details"
                              >
                                <span>{{ activeProduct + 1 }}</span
                                >/<span>{{ applications.length }}</span>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-lg-3 d-flex align-items-center"
                            *ngIf="app.subCategoryId?.contents?.length > 0"
                          >
                            <dl class="toggle">
                              <ng-container
                                *ngFor="
                                  let content of app.subCategoryId?.contents;
                                  let i = index
                                "
                              >
                                <dt>
                                  <a href="javascript:void(0)">{{
                                    content.title
                                  }}</a>
                                </dt>
                                <dd>
                                  <div class="py-3">
                                    <div
                                      class="content-desc"
                                      [innerHtml]="
                                        sanitizeHtml(content.description)
                                      "
                                    ></div>
                                  </div>
                                </dd>
                              </ng-container>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- End Tab panes -->
          </div>
        </div>
      </div>
    </section>
    <!-- Project Section ENds-->
  </main>
  <app-footer></app-footer>
</div>
<!-- End Page Wrap -->
