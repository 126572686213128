<app-loader></app-loader>

<!-- Navigation Panel -->
<nav class="main-nav stick-fixed">
  <div class="main-nav-sub full-wrapper">
    <div class="nav-logo-wrap local-scroll" *ngIf="logo">
      <a href="/" class="logo">
        <img [src]="this.httpService.fileUrl(logo)" alt="Vyara Tiles" />
      </a>
    </div>

    <!-- Mobile Menu Button -->
    <div class="mobile-nav" role="button" tabindex="0">
      <i class="mobile-nav-icon"></i>
      <span class="visually-hidden">Menu</span>
    </div>

    <!-- Main Menu -->
    <div class="inner-nav desktop-nav" *ngIf="menus.length > 0">
      <ul
        class="clearlist local-scroll"
        [ngClass]="{ 'scroll-nav': isHomePage }"
      >
        <ng-container *ngFor="let menu of menus">
          <ng-container *ngIf="menu.menuTemplate === 'Gallery'">
            <li class="position-static">
              <a href="#" class="mn-has-sub"
                >Gallery <i class="mi-chevron-down"></i
              ></a>
              <!-- Sub Megamenu -->
              <ul class="mn-sub mn-has-multi">
                <ng-container *ngFor="let sub of menu.subMenu; let i = index">
                  <li class="mn-sub-multi">
                    <div class="fadeInLeft">
                      <a [href]="'gallery/' + sub.link" class="megamenu-hover">
                        <img
                          *ngIf="sub.menuFile"
                          [src]="this.httpService.fileUrl(sub.menuFile)"
                          alt="{{ sub.title }}"
                          class="product-nav-img"
                        />
                        <div class="card-text-overlay">
                          {{ sub.title }}
                        </div>
                      </a>
                    </div>
                  </li>
                  <br *ngIf="(i + 1) % 5 === 0" />
                </ng-container>
                <!-- Sub Column -->
              </ul>
              <!-- End Sub Megamenu -->
            </li>
          </ng-container>
          <ng-container *ngIf="menu.menuTemplate !== 'Gallery'">
            <li
              [ngClass]="{
                'position-static': menu.menuTemplate == 'Product' && !isHomePage
              }"
            >
              <a
                *ngIf="menu.menuTemplate == 'Product' && !isHomePage"
                href="#"
                class="mn-has-sub"
              >
                {{ menu.menuName }}<i class="mi-chevron-down"></i>
              </a>
              <ul
                class="mn-sub mn-has-multi"
                *ngIf="
                  menu.menuTemplate == 'Product' &&
                  !isHomePage &&
                  menu.subMenu &&
                  menu.subMenu.length > 0
                "
              >
                <!-- Sub Column -->
                <ng-container *ngFor="let sub of menu.subMenu; let i = index">
                  <li class="mn-sub-multi">
                    <div class="fadeInLeft">
                      <a
                        [href]="'products/' + sub.productLink"
                        class="megamenu-hover"
                      >
                        <img
                          *ngIf="sub.menuFile"
                          [src]="this.httpService.fileUrl(sub.menuFile)"
                          alt="{{ sub.productName }}"
                          class="product-nav-img"
                        />
                        <div class="card-text-overlay">
                          {{ sub.productName }}
                        </div>
                      </a>
                    </div>
                  </li>
                  <br *ngIf="(i + 1) % 5 === 0" />
                </ng-container>
              </ul>

              <!-- Main menu item without a submenu -->
              <a
                *ngIf="
                  (menu.subMenu.length == 0 &&
                    menu.menuTemplate != 'CMS' &&
                    menu.menuTemplate != 'Project' &&
                    isHomePage) ||
                  (menu.subMenu.length > 0 &&
                    menu.menuTemplate == 'Product' &&
                    isHomePage)
                "
                href="#{{ menu.menuUrl }}"
              >
                {{ menu.menuName }}
              </a>

              <!-- Main menu item without a submenu -->
              <a
                *ngIf="
                  (menu.subMenu.length == 0 && menu.menuTemplate == 'CMS') ||
                  (menu.subMenu.length == 0 && menu.menuTemplate == 'Project')
                "
                [href]="menu.menuUrl"
              >
                {{ menu.menuName }}
              </a>

              <!-- Main menu item with a submenu -->
              <a
                *ngIf="
                  menu.subMenu.length > 0 && menu.menuTemplate != 'Product'
                "
                href="#"
                class="mn-has-sub"
              >
                {{ menu.menuName }} <i class="mi-chevron-down"></i>
              </a>

              <!-- Submenu items -->
              <ul
                class="mn-sub"
                *ngIf="
                  menu.subMenu &&
                  menu.subMenu.length > 0 &&
                  menu.menuTemplate != 'Product'
                "
              >
                <li *ngFor="let sub of menu.subMenu">
                  <a [href]="sub.menuUrl">
                    {{ sub.menuName }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
    <!-- End Main Menu -->

    <div class="header-serach-w">
      <div class="mobile-search">
        <div class="me-4" id="search-trigger">
          <a
            href="javascript:void(0)"
            class="opacity-1 no-hover"
            id="search-link"
          >
            <span class="cursor-pointer p-0">
              <i
                class="mi-search size-22 mt-2"
                aria-hidden="true"
                id="search-icon"
              ></i>
            </span>
          </a>
        </div>
        <div class="search-input-wrapper position-absolute" id="search-wrapper">
          <form class="form" [formGroup]="searchForm">
            <div class="search-wrap">
              <input
                type="text"
                class="form-control input-lg search-field round"
                id="search-input"
                placeholder="Search..."
                formControlName="searchInput"
                (input)="onSearchInput($event)"
              />
              <button
                class="search-button animate"
                type="submit"
                id="search-button"
                title="Start search"
                (click)="onSearchClick()"
              >
                <i
                  class="mi-search size-22"
                  aria-hidden="true"
                  id="search-icon"
                ></i>
              </button>
            </div>
          </form>
          <ul *ngIf="suggestions.length > 0" class="suggestions">
            <li *ngFor="let suggestion of suggestions">
              <ng-container *ngIf="suggestion.type == 'product'">
                <a [href]="'products/' + suggestion.productLink">{{
                  suggestion.productName
                }}</a>
              </ng-container>
              <ng-container *ngIf="suggestion.type == 'productSubCategory'">
                <a
                  [href]="
                    'products/' +
                    suggestion.productId?.productLink +
                    '/' +
                    suggestion.categoryId?.categoryLink +
                    '/' +
                    suggestion.subCategoryLink
                  "
                  >{{ suggestion.subCategoryName }}</a
                >
              </ng-container>
              <ng-container *ngIf="suggestion.type == 'productCategory'">
                <a
                  [href]="
                    'products/' +
                    suggestion.productId?.productLink +
                    '/' +
                    suggestion.categoryLink
                  "
                  >{{ suggestion.categoryName }}</a
                >
              </ng-container>
              <ng-container *ngIf="suggestion.type == 'project'">
                <a [href]="'project/' + suggestion.projectLink">{{
                  suggestion.projectName
                }}</a>
              </ng-container>
            </li>
          </ul>
          <ul
            *ngIf="suggestions.length == 0 && searchInput"
            class="suggestions"
          >
            <li>No data found</li>
          </ul>
        </div>
      </div>
      <div
        class="d-flex gap-2 justify-content-between fs-5 footer-social header-social-icons"
      >
        <a
          *ngIf="instaLink"
          [href]="instaLink"
          rel="noopener nofollow"
          target="_blank"
          class="text-decoration-none"
        >
          <i class="fa-instagram"></i>
        </a>
        <a
          *ngIf="fbLink"
          [href]="fbLink"
          rel="noopener nofollow"
          target="_blank"
          class="text-decoration-none"
        >
          <i class="fa-facebook-f"></i>
        </a>
        <a
          *ngIf="linkedinLink"
          [href]="linkedinLink"
          rel="noopener nofollow"
          target="_blank"
          class="text-decoration-none"
        >
          <i class="fa-linkedin-in"></i>
        </a>
        <a
          *ngIf="youtubeLink"
          [href]="youtubeLink"
          rel="noopener nofollow"
          target="_blank"
          class="text-decoration-none"
        >
          <i class="fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
</nav>
<!-- End Navigation Panel -->
