import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  backendurl = environment.backend_url;
  file_url = environment.file_url;

  constructor(public http: HttpClient, public router: Router) {}

  private getHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  GetAPI(path: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(this.backendurl + path, { headers });
  }

  PostAPI(path: string, data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(this.backendurl + path, data, { headers });
  }

  fileUrl(filename: string) {
    if (filename) {
      return this.file_url + filename;
    } else {
      return this.file_url;
    }
  }

  getFile(url: string, options: any) {
    return this.http.get(url, { ...options, responseType: 'blob' });
  }

  getSuggestions(query: string): Observable<string[]> {
    // Mock data, replace with actual API call
    const mockData = ['Apple', 'Banana', 'Orange', 'Grapes', 'Mango'];
    const filteredData = mockData.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    return of(filteredData);
  }
}
