import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { RedirectService } from './redirect.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(
    private redirectService: RedirectService,
    private location: Location
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const oldUrl = this.location.path(); // Get the current path
    this.redirectService.redirect(oldUrl); // Check and redirect if needed
    return false; // Prevent navigation to the actual route
  }
}
