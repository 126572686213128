import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  backendurl = environment.backend_url;
  redirects: any[] = [];

  constructor(private router: Router, private http: HttpClient) {
    this.fetchRedirects().subscribe((response) => {
      this.redirects = response.data;
    });
  }

  private fetchRedirects(): Observable<any> {
    return this.http.get(this.backendurl + 'url/getAllApi');
  }

  // Check if the URL should be redirected
  redirect(oldUrl: string): void {
    const redirect = this.redirects.find((r) => r.oldUrl === oldUrl);
    if (redirect) {
      this.router.navigate([redirect.newUrl]);
    } else {
      this.router.navigateByUrl('/page-not-found');
    }
  }
}
