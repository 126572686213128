<!-- Page Wrap -->
<div class="page" id="top">
  <app-header></app-header>

  <main id="main">
    <section class="breadcrumb-w">
      <div class="full-wrapper">
        <ul class="breadcrumb letters-spacing mb-0 mx-0">
          <li><a href="/">Home</a></li>
          <li>Projects</li>
        </ul>
      </div>
    </section>

    <!-- Project Section -->
    <section
      class="page-section"
      id="portfolio"
      *ngIf="projects.length > 0 && projectTypes.length > 0"
    >
      <div class="container">
        <div class="row position-relative">
          <div class="col-lg-12">
            <div class="wow charsAnimIn text-black text-start">
              <h1 class="hs-title-1 text-dark-gray">Projects</h1>
              <p class="hs-title-6 text-gray">Projects we are proud of</p>
            </div>
          </div>
        </div>
        <div class="row mb-60 mb-md-40 wow fadeInLeft">
          <div class="mb-50 mb-sm-0">
            <!-- Works Filter -->
            <div class="works-filter text-md-start text-lg-start">
              <a
                href="#"
                class="filter active"
                role="button"
                aria-pressed="true"
                data-filter="*"
                >All</a
              >
              <a
                *ngFor="let type of projectTypes; let i = index"
                [href]="'projects#' + type._id"
                [attr.data-filter]="'.' + type._id"
                class="filter"
                role="button"
                aria-pressed="false"
              >
                {{ type.projectType }}
              </a>
            </div>
            <!-- End Works Filter -->
          </div>
        </div>

        <!-- Works Grid -->
        <ul
          class="works-grid work-grid-4 work-grid-gut-lg masonry wow fadeInUp"
          data-wow-delay="0.1s"
          id="work-grid"
        >
          <!-- Work Item (Lightbox) -->
          <li
            *ngFor="let project of projects"
            class="work-item mix"
            [ngClass]="project.projectTypeId._id"
          >
            <a [href]="'project/' + project.projectLink">
              <div class="work-img">
                <img
                  [src]="this.httpService.fileUrl(project.desktopFile)"
                  [alt]="
                    project.imageTitle
                      ? project.imageTitle
                      : project.projectName
                  "
                />
              </div>
              <div class="work-intro text-start">
                <h3 class="work-title">{{ project.projectName }}</h3>
                <div class="work-descr">
                  {{ project.projectTypeId.projectType }}
                </div>
              </div>
            </a>
          </li>
          <!-- End Work Item -->
        </ul>
        <!-- End Works Grid -->
      </div>
    </section>
    <!-- Project Section ENds-->

    <!-- Marquee Section -->
    <!-- <div
      class="page-section container overflow-hidden"
      *ngIf="clients.length > 0"
    >
      <div class="row wow fadeInUpShort">
        <div class="col-lg-12 mb-2">
          <h2 class="hs-title-3 text-center text-dark-gray">Trusted By</h2>
        </div>
        <div class="col-md-12">
          <div class="small-item-carousel black owl-carousel mb-0 trusted-logo">
            <div class="logo-item" *ngFor="let client of clients">
              <img
                [src]="this.httpService.fileUrl(client.desktopFile)"
                alt="Company Name"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End Marquee Section -->

    <!-- End Logotypes Section-->
  </main>

  <app-footer></app-footer>
</div>
<!-- End Page Wrap -->
