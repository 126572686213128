<!-- Page Wrap -->
<div class="page" id="top">
  <app-header></app-header>
  <main id="main">
    <!-- Fullwidth Slider -->
    <div class="position-relative" *ngIf="isBrowser() && sliders.length > 0">
      <div class="home-section fullwidth-slider-fade bg-dark light-content">
        <ng-container *ngFor="let slider of sliders">
          <a [href]="slider.link">
            <section
              class="home-section bg-scroll light-content"
              [ngStyle]="{
                'background-image': isMobile
                  ? 'url(' + this.httpService.fileUrl(slider.mobileFile) + ')'
                  : 'url(' + this.httpService.fileUrl(slider.desktopFile) + ')'
              }"
            >
              <div
                class="container min-height-100vh d-flex align-items-center pt-100 pb-100 pt-sm-120 pb-sm-120"
              >
                <div class="col-lg-6 col-md-12 col-12">
                  <h1
                    class="banner_title banner-title uppercase text-Left"
                    [innerHtml]="sanitizeHtml(slider.title)"
                  ></h1>
                  <p
                    class="gentext16 size-20"
                    [innerHtml]="sanitizeHtml(slider.description)"
                  ></p>
                </div>
              </div>
            </section>
          </a>
        </ng-container>
      </div>
      <div class="slider-counter slider-counting-home-page"></div>
    </div>
    <!-- End Fullwidth Slider -->

    <!-- Product Section -->
    <ng-container *ngIf="products.length > 0">
      <section class="page-section z-index-1" id="products">
        <div class="container position-relative">
          <div class="row position-relative">
            <div class="wow charsAnimIn text-black text-center">
              <h1 class="hs-title-1 text-dark-gray">Products</h1>
              <p>Wide range of products for all your paving needs</p>
            </div>
            <!-- Team item -->
            <ng-container *ngFor="let product of products; let i = index">
              <div
                class="col-sm-6 col-lg-3 wow fadeInLeft"
                [attr.data-wow-delay]="(i + 1) * 0.1 + 's'"
              >
                <a
                  [href]="
                    product.productLink === 'freeform-premixes'
                      ? 'https://freeformbyvyara.in/'
                      : 'products/' + product.productLink
                  "
                >
                  <div
                    class="product-card position-relative"
                    [ngClass]="{ 'pt-2': i < 4, 'pt-4': i >= 4 }"
                  >
                    <div class="position-relative">
                      <img
                        [src]="this.httpService.fileUrl(product.desktopFile)"
                        [alt]="
                          product.imageTitle
                            ? product.imageTitle
                            : product.productName
                        "
                        class="h-auto product-img"
                      />
                      <span class="product-new-tag" *ngIf="product.isNewTag"
                        >NEW</span
                      >
                    </div>
                    <div class="box-details box-details-expand">
                      <strong>{{ product.productName }}</strong>
                      <p>
                        {{ product.productCategory }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </ng-container>
            <!-- End Team item -->
          </div>
        </div>
      </section>
    </ng-container>
    <!-- End Product Section -->
    <div class="home-page-image" *ngIf="bannerImages.length > 0">
      <section *ngFor="let banner of bannerImages" class="page-section pb-0">
        <div
          class="sustainability-w"
          [ngStyle]="{
            'background-image':
              'url(' + this.httpService.fileUrl(banner.desktopFile) + ')'
          }"
        >
          <div class="sustainability-text-overlay">
            <a class="susta-text" [href]="banner.bannerLink">
              <h3 class="mb-0">{{ banner.bannerTitle }}</h3>
              <h1 class="mb-2">
                <span
                  class="sustainibility-text-color"
                  [innerHtml]="sanitizeHtml(banner.bannerDescription)"
                >
                </span>
              </h1>
            </a>
          </div>
        </div>
      </section>
    </div>

    <!-- Project Section -->
    <div *ngIf="isBrowser() && projects.length > 0 && projectTypes.length > 0">
      <section class="page-section home-projects" id="projects">
        <div class="container overflow-hidden">
          <div class="row position-relative">
            <div class="wow charsAnimIn text-black text-center mb-20">
              <h1 class="hs-title-1 text-dark-gray">Projects</h1>
              <p class="mb-2">Projects we are proud of</p>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <!-- Nav Tabs -->
              <div class="text-center mb-40 mb-xxs-30 wow fadeInLeft">
                <ul class="nav nav-tabs tpl-tabs animate" role="tablist">
                  <!-- "All" Tab -->
                  <li class="nav-item" role="presentation">
                    <a
                      href="#item-1"
                      aria-controls="item-1"
                      class="nav-link active"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="true"
                    >
                      All
                    </a>
                  </li>

                  <!-- Dynamic Tabs for Project Types -->
                  <li
                    class="nav-item"
                    role="presentation"
                    *ngFor="let type of projectTypes; let i = index"
                  >
                    <a
                      href="#item-{{ i + 2 }}"
                      [attr.aria-controls]="'item-' + (i + 2)"
                      class="nav-link"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      {{ type.projectType }}
                    </a>
                  </li>
                </ul>
              </div>
              <!-- End Nav Tabs -->

              <!-- Tab panes -->
              <div class="tab-content tpl-minimal-tabs-cont wow fadeInUp">
                <!-- All Projects Pane -->
                <div
                  id="item-1"
                  role="tabpanel"
                  class="tab-pane fade show active"
                >
                  <!-- Projects Carousel -->
                  <div class="testimonials-slider-3" style="padding: 0">
                    <!-- Dynamic Project Items -->
                    <ng-container *ngFor="let project of projects">
                      <div class="project-view">
                        <div class="work-item mix">
                          <a [href]="'project/' + project.projectLink">
                            <div class="work-img" data-wow-delay="0.1s">
                              <img
                                [src]="
                                  this.httpService.fileUrl(project.desktopFile)
                                "
                                [alt]="
                                  project.imageTitle
                                    ? project.imageTitle
                                    : project.projectName
                                "
                              />
                            </div>
                            <div class="work-intro text-start">
                              <h3 class="work-title">
                                {{ project.projectName }}
                              </h3>
                              <div class="work-descr">
                                {{ project.projectTypeId.projectType }}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <!-- End Projects Carousel -->
                </div>

                <!-- Dynamic Project Type Tabs -->
                <div
                  *ngFor="let type of projectTypes; let i = index"
                  id="item-{{ i + 2 }}"
                  role="tabpanel"
                  class="tab-pane fade"
                >
                  <!-- Projects Carousel -->
                  <div class="testimonials-slider-3" style="padding: 0">
                    <!-- Dynamic Project Items -->
                    <div
                      class="project-view"
                      *ngFor="
                        let project of projects
                          | filterProjectsByType : type.projectType
                      "
                    >
                      <div class="work-item mix development">
                        <a [href]="'project/' + project.projectLink">
                          <div class="work-img" data-wow-delay="0.1s">
                            <img
                              [src]="
                                this.httpService.fileUrl(project.desktopFile)
                              "
                              [alt]="
                                project.imageTitle
                                  ? project.imageTitle
                                  : project.projectName
                              "
                            />
                          </div>
                          <div class="work-intro text-start">
                            <h3 class="work-title">
                              {{ project.projectName }}
                            </h3>
                            <div class="work-descr">
                              {{ project.projectTypeId.projectType }}
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- End Projects Carousel -->
                </div>
              </div>
              <!-- End Tab panes -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Project Section Ends-->

    <ng-container *ngIf="bannerVideos.length > 0">
      <div class="home-page-videos">
        <section
          class="page-section"
          *ngFor="let banner of bannerVideos; let i = index"
        >
          <div class="home-video-container">
            <div class="container position-relative">
              <div class="video-overlay"></div>
              <video
                class="o-figure__video lazyloaded"
                preload="auto"
                loop=""
                playsinline=""
                muted=""
                autoplay=""
                [src]="this.httpService.fileUrl(banner.mobileFile)"
                data-component="video-bg"
              ></video>
              <div class="video-btn">
                <a
                  target="_blank"
                  [href]="this.httpService.fileUrl(banner.desktopFile)"
                  class="btn-hover-anim lightbox-gallery-2 mfp-iframe"
                  >WATCH CORPORATE VIDEO</a
                >
              </div>
            </div>
          </div>
        </section>
      </div>
    </ng-container>

    <!-- Marquee Section -->
    <ng-container *ngIf="isBrowser() && clients.length > 0">
      <div class="page-section container overflow-hidden mb-3 pt-3">
        <div class="row wow fadeInUpShort">
          <div class="col-lg-12 mb-2">
            <h2 class="hs-title-3 text-center text-dark-gray">Trusted By</h2>
          </div>
          <div class="col-md-12">
            <div class="small-item-carousel black mb-0 trusted-logo">
              <div class="logo-item" *ngFor="let client of clients">
                <img
                  [src]="this.httpService.fileUrl(client.desktopFile)"
                  alt="Company Name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End Marquee Section -->
  </main>
  <app-footer></app-footer>
  <app-freeform></app-freeform>
</div>
<!-- End Page Wrap -->
