<!-- Page Wrap -->
<div class="page" id="top">
  <app-header></app-header>

  <main id="main">
    <section class="breadcrumb-w">
      <div class="full-wrapper">
        <ul class="breadcrumb letters-spacing mb-0 mx-0">
          <li><a href="/">Home</a></li>
          <li><a href="/projects">Projects</a></li>
          <li>{{ project.projectName }}</li>
        </ul>
      </div>
    </section>
    <!-- Image Section with Text Overlay -->
    <section class="image-section">
      <div class="image-wrapper">
        <img
          [src]="this.httpService.fileUrl(project.bannerDesktopFile)"
          alt="{{ project.projectName }}"
          class="img-fluid desktop-banner"
        />
        <img
          [src]="this.httpService.fileUrl(project.bannerMobileFile)"
          alt="{{ project.projectName }}"
          class="img-fluid mobile-banner"
        />
        <div class="product-detail-text-overlay">
          <div
            class="py-3 px-4"
            [innerHtml]="sanitizeHtml(project.projectContent)"
          ></div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center my-5">
          <div class="col-lg-7 mx-auto">
            <div
              class="fs-5 mb-0 wow fadeInUp text-gray justify project-desc"
              [innerHtml]="sanitizeHtml(project.description)"
            ></div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Image Section with Text Overlay -->

    <section
      class="page-section bg-gray-light-2"
      *ngIf="projectGallery.length > 0"
    >
      <div class="container position-relative">
        <div class="text-center mb-5">
          <h2 class="hs-title-3 mb-2 text-dark-gray">Gallery</h2>
          <p class="text-gray">Please click on an image below to enlarge</p>
        </div>

        <div class="row gx-no gy-no wow fadeInUp" data-wow-delay="0">
          <div class="col-lg-4" *ngIf="projectGallery.length > 0">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[0]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[0]?.title ? projectGallery[0]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[0]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[0]?.title ? projectGallery[0]?.title : ''
                  "
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[1]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[1]?.title ? projectGallery[1]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[1]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[1]?.title ? projectGallery[1]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="projectGallery.length > 2">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[2]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[2]?.title ? projectGallery[2]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[2]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[2]?.title ? projectGallery[2]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 3">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(3, 6)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  [title]="gallery.title ? gallery.title : ''"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    [alt]="gallery.title ? gallery.title : ''"
                  />
                </a>
              </div>
            </div>
          </ng-container>

          <div class="col-lg-8" *ngIf="projectGallery.length > 6">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[6]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[6]?.title ? projectGallery[6]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[6]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[6]?.title ? projectGallery[6]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <div class="col-lg-4" *ngIf="projectGallery.length > 7">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[7]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[7]?.title ? projectGallery[7]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[7]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[7]?.title ? projectGallery[7]?.title : ''
                  "
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 8">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[8]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[8]?.title ? projectGallery[8]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[8]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[8]?.title ? projectGallery[8]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 9">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(9, 12)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  [title]="gallery.title ? gallery.title : ''"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    [alt]="gallery.title ? gallery.title : ''"
                  />
                </a>
              </div>
            </div>
          </ng-container>
          <div class="col-lg-4" *ngIf="projectGallery.length > 12">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[12]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[12]?.title ? projectGallery[12]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[12]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[12]?.title ? projectGallery[12]?.title : ''
                  "
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 13">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[13]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[13]?.title ? projectGallery[13]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[13]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[13]?.title ? projectGallery[13]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="projectGallery.length > 14">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[14]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[14]?.title ? projectGallery[14]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[14]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[14]?.title ? projectGallery[14]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 15">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(15, 18)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  [title]="gallery.title ? gallery.title : ''"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    [alt]="gallery.title ? gallery.title : ''"
                  />
                </a>
              </div>
            </div>
          </ng-container>
          <div class="col-lg-8" *ngIf="projectGallery.length > 18">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[18]?.desktopFile)
                "
                class="lightbox mfp-image"
                [title]="
                  projectGallery[18]?.title ? projectGallery[18]?.title : ''
                "
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[18]?.desktopFile)
                  "
                  [alt]="
                    projectGallery[18]?.title ? projectGallery[18]?.title : ''
                  "
                />
              </a>
            </div>
          </div>
          <div class="col-lg-4" *ngIf="projectGallery.length > 19">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[19]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[19]?.title ? projectGallery[19]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[19]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[19]?.title ? projectGallery[19]?.title : ''
                  }}"
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 20">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[20]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[20]?.title ? projectGallery[20]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[20]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[20]?.title ? projectGallery[20]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 21">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(20, 23)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  title="{{ gallery.title ? gallery.title : '' }}"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    alt="{{ gallery.title ? gallery.title : '' }}"
                  />
                </a>
              </div>
            </div>
          </ng-container>
          <div class="col-lg-4" *ngIf="projectGallery.length > 24">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[24]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[24]?.title ? projectGallery[24]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[24]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[24]?.title ? projectGallery[24]?.title : ''
                  }}"
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 25">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[25]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[25]?.title ? projectGallery[25]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[25]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[25]?.title ? projectGallery[25]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="projectGallery.length > 26">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[26]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[26]?.title ? projectGallery[26]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[26]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[26]?.title ? projectGallery[26]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 27">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(27, 30)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  title="{{ gallery.title ? gallery.title : '' }}"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    alt="{{ gallery.title ? gallery.title : '' }}"
                  />
                </a>
              </div>
            </div>
          </ng-container>
          <div class="col-lg-8" *ngIf="projectGallery.length > 30">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[30]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[30]?.title ? projectGallery[30]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[30]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[30]?.title ? projectGallery[30]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-4" *ngIf="projectGallery.length > 31">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[31]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[31]?.title ? projectGallery[31]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[31]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[31]?.title ? projectGallery[31]?.title : ''
                  }}"
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 32">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[32]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[32]?.title ? projectGallery[32]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[32]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[32]?.title ? projectGallery[32]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 33">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(33, 36)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  title="{{ gallery.title ? gallery.title : '' }}"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    alt="{{ gallery.title ? gallery.title : '' }}"
                  />
                </a>
              </div>
            </div>
          </ng-container>
          <div class="col-lg-4" *ngIf="projectGallery.length > 36">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[36]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[36]?.title ? projectGallery[36]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[36]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[36]?.title ? projectGallery[36]?.title : ''
                  }}"
                />
              </a>
            </div>
            <div class="work-img g-mb-1" *ngIf="projectGallery.length > 37">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[37]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[37]?.title ? projectGallery[37]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[37]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[37]?.title ? projectGallery[37]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-8" *ngIf="projectGallery.length > 38">
            <div class="work-img g-mb-1">
              <div class="work-img-bg post-prev-img"></div>
              <a
                [href]="
                  this.httpService.fileUrl(projectGallery[38]?.desktopFile)
                "
                class="lightbox mfp-image"
                title="{{
                  projectGallery[38]?.title ? projectGallery[38]?.title : ''
                }}"
              >
                <img
                  [src]="
                    this.httpService.fileUrl(projectGallery[38]?.desktopFile)
                  "
                  alt="{{
                    projectGallery[38]?.title ? projectGallery[38]?.title : ''
                  }}"
                />
              </a>
            </div>
          </div>
          <ng-container *ngIf="projectGallery.length > 39">
            <div
              class="col-lg-4"
              *ngFor="let gallery of projectGallery.slice(39, 42)"
            >
              <div class="work-img g-mb-1">
                <div class="work-img-bg post-prev-img"></div>
                <a
                  [href]="this.httpService.fileUrl(gallery.desktopFile)"
                  class="lightbox mfp-image"
                  title="{{ gallery.title ? gallery.title : '' }}"
                >
                  <img
                    [src]="this.httpService.fileUrl(gallery.desktopFile)"
                    alt="{{ gallery.title ? gallery.title : '' }}"
                  />
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
  </main>
  <app-footer></app-footer>
</div>
<!-- End Page Wrap -->
