import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { IndividualConfig, ToastrModule, ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FilterCustomersByTypePipe } from '../filter-customers-by-type.pipe';
import { HttpClient } from '@angular/common/http';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FilterApplicationByTypePipe } from '../filter-application-by-type.pipe';

@Component({
  selector: 'app-cms',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule,
    FilterCustomersByTypePipe,
    FilterApplicationByTypePipe,
  ],
  templateUrl: './cms.component.html',
  styleUrl: './cms.component.css',
})
export class CmsComponent implements OnInit {
  menuUrl = '';
  menu: any = {};
  cms: any = {};
  banner: any = {};
  customerTypes: any[] = [];
  customers: any[] = [];
  applications: any[] = [];
  sub_menus: any[] = [];
  peoples: any[] = [];
  downloads: any[] = [];
  investors: any[] = [];
  faqs: any[] = [];
  uncarbonFaqs: any[] = [];
  faqsLeft: any[] = [];
  faqsRight: any[] = [];
  uncarbonFaqsLeft: any[] = [];
  uncarbonFaqsRight: any[] = [];
  address: any[] = [];
  jobs: any[] = [];
  appTypes: any[] = [];
  products: any[] = [];
  productLeft: any[] = [];
  productRight: any[] = [];
  dealers: { [key: string]: any[] } = {};
  applyNowPage = false;
  inquiryNowPage = false;
  subMenuShowTab = false;
  contactForm!: FormGroup;
  jobForm!: FormGroup;
  pageName = '';
  pageSubName = '';
  jobId = '';
  cvFilePreview: string | ArrayBuffer | null = null;
  activeProduct: number = -1;
  currentPage = 1; // Tracks the current page
  pageSize = 8;
  isLoading = false;
  fileName: string = '';

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    const menuUrl = this.route.snapshot.paramMap.get('menuUrl');
    this.menuUrl = menuUrl || '';
    if (this.menuUrl) {
      this.getMenu();
    } else {
      this.router.navigateByUrl('/redirect-page');
    }
    this.contactForm = this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      message: ['', Validators.required],
      productIds: this.formBuilder.array([], [Validators.required]),
    });
    this.jobForm = this.formBuilder.group({
      surName: ['', [Validators.required, Validators.minLength(3)]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      fatherName: ['', [Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      phone1: ['', [Validators.pattern('^[0-9]+$')]],
      permanentAddress: ['', [Validators.required]],
      persentAddress: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      dob: ['', [Validators.required]],
      placeBirth: [''],
      presentSalary: ['', Validators.required],
      expectedSalary: ['', Validators.required],
      uploadCV: ['', [Validators.required]],
      languages: this.formBuilder.array([
        this.createLanguageFormGroup(),
        this.createLanguageFormGroup(),
        this.createLanguageFormGroup(),
      ]),
      educations: this.formBuilder.array([
        this.createEducationFormGroup(),
        this.createEducationFormGroup(),
        this.createEducationFormGroup(),
        this.createEducationFormGroup(),
      ]),
      experiences: this.formBuilder.array([
        this.createExperienceFormGroup(),
        this.createExperienceFormGroup(),
        this.createExperienceFormGroup(),
        this.createExperienceFormGroup(),
      ]),
    });
  }

  createExperienceFormGroup(): FormGroup {
    return this.formBuilder.group({
      companyName: [''],
      designation: [''],
      from: [''],
      to: [''],
      salary: [''],
      duties: [''],
    });
  }

  createEducationFormGroup(): FormGroup {
    return this.formBuilder.group({
      examination: [''],
      institute: [''],
      board: [''],
      subject: [''],
      year: [''],
      class: [''],
      percentage: [''],
    });
  }

  createLanguageFormGroup(): FormGroup {
    return this.formBuilder.group({
      language: [''],
      speak: [false],
      read: [false],
      write: [false],
    });
  }

  get languages(): FormArray {
    return this.jobForm.get('languages') as FormArray;
  }

  get educations(): FormArray {
    return this.jobForm.get('educations') as FormArray;
  }

  get experiences(): FormArray {
    return this.jobForm.get('experiences') as FormArray;
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.cvFilePreview = reader.result as string;
        this.jobForm.get('uploadCV')?.setValue(file);
      };
      if (input.files && input.files[0]) {
        this.fileName = input.files[0].name;
      } else {
        this.fileName = 'No file chosen';
      }
      reader.readAsDataURL(file);
    }
  }

  onSubmitJobForm(): void {
    if (this.jobForm.valid && this.jobId) {
      const formData = new FormData();
      formData.append('jobId', this.jobId);
      formData.append('surName', this.jobForm.get('surName')?.value);
      formData.append('firstName', this.jobForm.get('firstName')?.value);
      if (this.jobForm.get('fatherName')?.value) {
        formData.append('fatherName', this.jobForm.get('fatherName')?.value);
      }
      formData.append('phone', this.jobForm.get('phone')?.value);
      formData.append('phone1', this.jobForm.get('phone1')?.value);
      formData.append('email', this.jobForm.get('email')?.value);
      formData.append('dob', this.jobForm.get('dob')?.value);
      formData.append('placeBirth', this.jobForm.get('placeBirth')?.value);
      formData.append(
        'persentAddress',
        this.jobForm.get('persentAddress')?.value
      );
      formData.append(
        'permanentAddress',
        this.jobForm.get('permanentAddress')?.value
      );
      if (this.jobForm.get('uploadCV')?.value) {
        formData.append('uploadCV', this.jobForm.get('uploadCV')?.value);
      }
      formData.append(
        'presentSalary',
        this.jobForm.get('presentSalary')?.value
      );
      formData.append(
        'expectedSalary',
        this.jobForm.get('expectedSalary')?.value
      );
      if (this.languages.length > 0) {
        this.languages.controls.forEach((group, index) => {
          const content = group.value;
          if (content.language) {
            formData.append(`languages[${index}][language]`, content.language);
            formData.append(`languages[${index}][speak]`, content.speak);
            formData.append(`languages[${index}][read]`, content.read);
            formData.append(`languages[${index}][write]`, content.write);
          }
        });
      }
      if (this.educations.length > 0) {
        this.educations.controls.forEach((group, index) => {
          const content = group.value;
          if (content.examination) {
            formData.append(
              `educations[${index}][examination]`,
              content.examination
            );
          }
          if (content.institute) {
            formData.append(
              `educations[${index}][institute]`,
              content.institute
            );
          }
          formData.append(`educations[${index}][board]`, content.board);
          formData.append(`educations[${index}][subject]`, content.subject);
          formData.append(`educations[${index}][year]`, content.year);
          formData.append(`educations[${index}][class]`, content.class);
          formData.append(
            `educations[${index}][percentage]`,
            content.percentage
          );
        });
      }
      if (this.experiences.length > 0) {
        this.experiences.controls.forEach((group, index) => {
          const content = group.value;
          if (content.companyName) {
            formData.append(
              `experiences[${index}][companyName]`,
              content.companyName
            );
          }
          if (content.designation) {
            formData.append(
              `experiences[${index}][designation]`,
              content.designation
            );
          }
          formData.append(`experiences[${index}][from]`, content.from);
          formData.append(`experiences[${index}][to]`, content.to);
          formData.append(`experiences[${index}][salary]`, content.salary);
          formData.append(`experiences[${index}][duties]`, content.duties);
        });
      }
      this.httpService.PostAPI('jobForm/createApi', formData).subscribe(
        (resdata: any) => {
          if (resdata.isSuccess) {
            this.toastr.success(resdata.message);
            this.jobForm.reset();
          } else {
            this.toastr.error(resdata.message);
          }
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      this.toastr.error('Form is invalid');
    }
  }

  get productIds(): FormArray {
    return this.contactForm.get('productIds') as FormArray;
  }

  onCheckboxChange(e: any) {
    const productsArray: FormArray = this.contactForm.get(
      'productIds'
    ) as FormArray;

    if (e.target.checked) {
      productsArray.push(this.formBuilder.control(e.target.value));
    } else {
      const index = productsArray.controls.findIndex(
        (x) => x.value === e.target.value
      );
      productsArray.removeAt(index);
    }
  }

  getMenu() {
    this.httpService.GetAPI(`menu/getMenu/${this.menuUrl}`).subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.menu = resdata.data;
          this.banner = resdata.data?.Banner;
          if (this.menu.menuTemplate == 'CMS') {
            this.pageName = resdata.data.CMS ? resdata.data?.CMS?.pageName : '';
            this.pageSubName = resdata.data?.CMS
              ? resdata.data?.CMS?.pageSubName
              : '';
            this.cms = resdata.data?.CMS?.rows;
          }
          if (this.menu.menuTemplate == 'OurCustomer') {
            this.get_customer_type();
            this.get_our_customers();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'People') {
            this.get_people();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Downloads') {
            this.get_downloads();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Investors') {
            this.get_investors();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'FAQs') {
            this.get_faqs();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'UncarbonFAQs') {
            this.get_uncarbon_faqs();
            this.subMenuShowTab = true;
          }
          if (
            this.menu.parentId &&
            this.menu.parentId?.menuTemplate == 'SubMenu'
          ) {
            this.get_sub_menu(this.menu.parentId._id);
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Address') {
            this.get_address();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'EnquiryForm') {
            this.inquiryNowPage = true;
            this.get_products();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Dealers') {
            this.get_dealers();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Jobs') {
            this.get_jobs();
            this.subMenuShowTab = true;
          }
          if (this.menu.menuTemplate == 'Gallery') {
            this.getGallery();
            this.get_application();
          }
        } else {
          this.router.navigateByUrl('/redirect-page');
        }
      },
      (err) => {
        this.router.navigateByUrl('/redirect-page');
      }
    );
  }

  get_products() {
    this.httpService.GetAPI('product/getAllProduct').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.products = resdata.data;
          const halfway = Math.ceil(this.products.length / 2);
          this.productLeft = this.products.slice(0, halfway);
          this.productRight = this.products.slice(halfway);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  onSubmitContactForm(): void {
    if (this.contactForm.valid) {
      const data = this.contactForm.value;
      this.httpService.PostAPI('enquiryForm/createApi', data).subscribe(
        (resdata: any) => {
          if (resdata.isSuccess) {
            this.toastr.success(resdata.message);
            const productsArray: FormArray = this.contactForm.get(
              'productIds'
            ) as FormArray;
            while (productsArray.length) {
              productsArray.removeAt(0); // Remove all controls in the FormArray
            }
            this.contactForm.reset();
            this.contactForm.markAsPristine();
            this.contactForm.markAsUntouched();
            //window.location.reload();
          } else {
            this.toastr.error(resdata.message);
          }
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      this.contactForm.markAllAsTouched();
      this.toastr.error('Form is invalid');
    }
  }

  get_application() {
    this.httpService.GetAPI('application/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.appTypes = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_jobs() {
    this.httpService.GetAPI('job/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.jobs = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_dealers() {
    this.httpService.GetAPI('dealers/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.dealers = this.transformDataByCity(resdata.data);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  transformDataByCity(data: any[]): { [key: string]: any[] } {
    const cityMap: { [key: string]: any[] } = {};

    data.forEach((item) => {
      // Convert phone field from string to array
      const phoneArray = item.phone
        ? item.phone.split('|').map((p: string) => p.trim())
        : [];

      // Create a new object with phone field as an array
      const formattedItem = {
        ...item,
        phone: phoneArray,
      };

      // Group by city
      if (!cityMap[item.city]) {
        cityMap[item.city] = [];
      }
      cityMap[item.city].push(formattedItem);
    });
    return cityMap;
  }

  dealerLimit = 1;

  getCityKeys(): string[] {
    return Object.keys(this.dealers);
  }

  getDealers(city: string) {
    return this.dealers[city].slice(0, this.dealerLimit);
  }

  getRemainingDealers(city: string) {
    return this.dealers[city].slice(this.dealerLimit);
  }

  get_address() {
    this.httpService.GetAPI('address/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.address = resdata.data.map((item: any) => {
            return {
              ...item,
              phone: item.phone
                ? item.phone.split('|').map((p: string) => p.trim())
                : [],
              mobile: item.mobile
                ? item.mobile.split('|').map((p: string) => p.trim())
                : [],
            };
          });
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  downloadPdf(fileUrl: string, fileName: string) {
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob) => {
      const file = new Blob([blob], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = fileName;
      link.click();
    });
  }

  get_uncarbon_faqs() {
    this.httpService.GetAPI('uncarbonFaqs/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.uncarbonFaqs = resdata.data;
          const halfway = Math.ceil(this.uncarbonFaqs.length / 2);
          this.uncarbonFaqsLeft = this.uncarbonFaqs.slice(0, halfway);
          this.uncarbonFaqsRight = this.uncarbonFaqs.slice(halfway);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_faqs() {
    this.httpService.GetAPI('faqs/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.faqs = resdata.data;
          const halfway = Math.ceil(this.faqs.length / 2);
          this.faqsLeft = this.faqs.slice(0, halfway);
          this.faqsRight = this.faqs.slice(halfway);
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_downloads() {
    this.httpService.GetAPI('downloads/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.downloads = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_investors() {
    this.httpService.GetAPI('investors/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.investors = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_people() {
    this.httpService.GetAPI('people/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.peoples = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_sub_menu(parentId: any) {
    this.httpService.GetAPI(`menu/getSubMenu/${parentId}`).subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.sub_menus = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  iframeUrl(value: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  get_customer_type() {
    this.httpService.GetAPI('customerType/getAllType').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.customerTypes = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  get_our_customers() {
    this.httpService.GetAPI('customer/getAllApi').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.customers = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  applyNow(id: string) {
    this.applyNowPage = true;
    this.jobs = [];
    this.jobId = id;
  }

  // @HostListener('window:scroll', [])
  // onScroll(): void {
  //   const scrollPosition = window.innerHeight + window.scrollY;
  //   const pageHeight = document.body.offsetHeight;

  //   if (scrollPosition >= pageHeight - 100 && !this.isLoading) {
  //     this.getGallery();
  //   }
  // }

  // getGallery() {
  //   if (this.isLoading) return;
  //   this.isLoading = true;
  //   const data = { page: this.currentPage, pageSize: this.pageSize };
  //   this.httpService
  //     .PostAPI('productGallery/getAllApplication', data)
  //     .subscribe(
  //       (resdata: any) => {
  //         if (resdata.isSuccess) {
  //           this.applications = [...this.applications, ...resdata.data];
  //           this.currentPage++; // Move to the next page
  //           this.isLoading = false; // Reset the loading flag
  //         }
  //       },
  //       (err) => {
  //         this.toastr.error(err.error.message);
  //         this.isLoading = false; // Reset the loading flag
  //       }
  //     );
  // }

  getGallery() {
    this.httpService.GetAPI('productGallery/getAllApplication').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.applications = resdata.data;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  setActiveProduct(index: number): void {
    this.activeProduct = index;
  }
}
