import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { FreeformComponent } from '../freeform/freeform.component';
import { HeaderComponent } from '../header/header.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FilterProjectsByTypePipe } from '../filter-projects-by-type.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FooterComponent,
    FreeformComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule,
    FilterProjectsByTypePipe,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  // @ViewChild('myElement', { static: false }) myElement!: ElementRef;
  // @ViewChild('myElement1', { static: false }) myElement1!: ElementRef;
  sliders: any[] = [];
  products: any[] = [];
  bannerImages: any[] = [];
  bannerVideos: any[] = [];
  clients: any[] = [];
  projectTypes: any[] = [];
  projects: any[] = [];
  isMobile: boolean = false;

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.checkScreenSize();
    }
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.getSlider();
    this.getProjects();
    this.getClient();
    this.getProducts();
    this.getHomePageBanner();
    this.getProjectType();
  }

  // ngAfterViewInit(): void {
  //   if (this.myElement) {
  //     this.getSlider();
  //   }
  //   if (this.myElement1) {
  //     this.getProjects();
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.checkScreenSize();
    }
  }

  checkScreenSize() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth <= 768; // or any breakpoint for mobile
    }
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getProjectType() {
    this.httpService.GetAPI('projectType/getAllProjectType').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.projectTypes = resdata.data;
          this.cdr.detectChanges();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getProjects() {
    this.httpService.GetAPI('project/getAllProject').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.projects = resdata.data;
          this.cdr.detectChanges();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getHomePageBanner() {
    this.httpService
      .GetAPI('banner/getBanner/66a780b8cf5b23697160d784')
      .subscribe(
        (resdata: any) => {
          if (resdata.isSuccess) {
            const banners = resdata.data;
            this.bannerImages = banners.filter(
              (banner: any) => banner.bannerType === 'Image'
            );
            this.bannerVideos = banners.filter(
              (banner: any) => banner.bannerType === 'Video'
            );
            this.cdr.detectChanges();
          }
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
  }

  getClient() {
    this.httpService.GetAPI('client/getAllClient').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.clients = resdata.data;
          this.cdr.detectChanges();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getSlider() {
    this.httpService.GetAPI('slider/getAllSlider').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.sliders = resdata.data;
          this.cdr.detectChanges();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getProducts() {
    this.httpService.GetAPI('product/getAllProduct').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.products = resdata.data;
          this.cdr.detectChanges();
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }
}
