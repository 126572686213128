<!-- Page Wrap -->
<div class="page" id="top">
  <app-header></app-header>

  <main id="main">
    <section class="breadcrumb-w">
      <div class="full-wrapper">
        <ul class="breadcrumb letters-spacing mb-0 mx-0">
          <li><a href="/">Home</a></li>
          <li *ngIf="menu.parentId?.menuName">
            {{ menu.parentId.menuName }}
          </li>
          <li>{{ menu.menuName }}</li>
        </ul>
      </div>
    </section>

    <!-- Image Section with Text Overlay -->
    <section class="image-section" *ngIf="banner">
      <div class="image-wrapper bg-dark-alpha-30">
        <img
          *ngIf="banner.desktopFile"
          [src]="this.httpService.fileUrl(banner.desktopFile)"
          [alt]="banner.bannerTitle"
          class="img-fluid desktop-banner"
        />
        <img
          *ngIf="banner.mobileFile"
          [src]="this.httpService.fileUrl(banner.mobileFile)"
          [alt]="banner.bannerTitle"
          class="img-fluid mobile-banner"
        />
        <div class="overlay-text" *ngIf="banner.bannerTitle">
          <h1 class="inner-banner-text">
            <span class="">
              {{ banner.bannerTitle }}
            </span>
          </h1>
        </div>
      </div>
    </section>
    <!-- End Image Section with Text Overlay -->

    <!-- Works Filter -->
    <section
      class="page-tabs-space page-section pb-4 wow fadeInUp animated"
      data-wow-delay="0.4s"
      *ngIf="sub_menus.length > 0 && subMenuShowTab"
    >
      <div class="works-filter text-center z-index-1">
        <a
          *ngFor="let menu of sub_menus; let i = index"
          [href]="menu.menuUrl"
          role="button"
          [ngClass]="menu.menuUrl == menuUrl ? 'active' : ''"
          aria-pressed="false"
          >{{ menu.menuName }}</a
        >
      </div>
    </section>
    <!-- End Works Filter -->

    <!-- Main Menu -->
    <section class="page-section" *ngIf="cms?.length > 0">
      <div class="container position-relative">
        <div class="row">
          <div
            class="text-center mb-60 mb-sm-40"
            *ngIf="pageName && pageSubName"
          >
            <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h3 class="hs-title-5 mb-2">{{ pageName }}</h3>
              <p
                class="section-descr mb-0 wow fadeIn"
                data-wow-delay="0.2s"
                data-wow-duration="1.2s"
              >
                {{ pageSubName }}
              </p>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let row of cms">
          <div
            class="row mb-50"
            [ngClass]="{
              'reverse-div':
                row.rightContentType === 'Iframe' ||
                row.rightContentType == 'Image'
            }"
          >
            <div
              class="col-lg-6 d-table"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.leftContentType == 'Editor'
              "
            >
              <div
                class="d-table-cell align-middle"
                [innerHtml]="sanitizeHtml(row.leftContentValue)"
              ></div>
            </div>
            <div
              class="col-lg-6"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.leftContentType == 'Image'
              "
            >
              <div class="wow fadeInUp">
                <img
                  [src]="this.httpService.fileUrl(row.leftContentValue)"
                  alt=""
                  loading="lazy"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-lg-6"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.leftContentType == 'Iframe'
              "
            >
              <div class="wow fadeInUp">
                <iframe
                  width="100%"
                  height="400"
                  [src]="iframeUrl(row.leftContentValue)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              class="col-lg-6"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.rightContentType == 'Iframe'
              "
            >
              <div class="wow fadeInUp">
                <iframe
                  width="100%"
                  height="400"
                  [src]="iframeUrl(row.rightContentValue)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              class="col-lg-6 d-table"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.rightContentType == 'Editor'
              "
            >
              <div
                class="d-table-cell align-middle"
                [innerHtml]="sanitizeHtml(row.rightContentValue)"
              ></div>
            </div>
            <div
              class="col-lg-6"
              *ngIf="
                row.isActive &&
                row.rowContentType == 'TwoColumn' &&
                row.rightContentType == 'Image'
              "
            >
              <div class="wow fadeInUp">
                <img
                  [src]="this.httpService.fileUrl(row.rightContentValue)"
                  alt=""
                  loading="lazy"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-lg-12"
              *ngIf="row.isActive && row.rowContentType == 'FullEditor'"
            >
              <div
                class="mb-30 wow fadeInUp"
                [innerHtml]="sanitizeHtml(row.fullContentValue)"
              ></div>
            </div>
          </div>
        </ng-container>
      </div>
    </section>

    <section
      class="page-section pt-0"
      *ngIf="customers.length > 0 && customerTypes.length > 0"
    >
      <div class="position-relative">
        <div class="row">
          <div class="">
            <!-- Nav Tabs -->
            <div
              class="text-center mb-40 mb-xxs-30 wow fadeInUp animated"
              data-wow-delay="0.8s"
            >
              <ul class="nav tpl-minimal-tabs animate p-3" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    href="#all"
                    aria-controls="all"
                    class="nav-link active"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    >All</a
                  >
                </li>

                <li
                  class="nav-item"
                  role="presentation"
                  *ngFor="let type of customerTypes; let i = index"
                >
                  <a
                    href="#item-{{ i + 1 }}"
                    [attr.aria-controls]="'item-' + (i + 1)"
                    class="nav-link"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    >{{ type.customerType }}</a
                  >
                </li>
              </ul>
            </div>
            <!-- End Nav Tabs -->

            <!-- Tab panes -->
            <div
              class="tab-content tpl-minimal-tabs-cont text-center container"
            >
              <div class="tab-pane fade show active" id="all" role="tabpanel">
                <div class="container">
                  <div
                    *ngFor="let type of customerTypes; let i = index"
                    class="row d-flex align-items-center"
                  >
                    <div class="col-12 text-start">
                      <h3 class="text-gray text-dark hs-title-5 mt-3">
                        {{ type.customerType }}
                      </h3>
                    </div>
                    <hr />
                    <div
                      *ngFor="
                        let customer of customers
                          | filterCustomersByType : type.customerType
                      "
                      class="col-lg-2 col-md-4 col-sm-6 col-6 mb-4"
                    >
                      <img
                        [src]="this.httpService.fileUrl(customer.desktopFile)"
                        [alt]="type.customerType"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                *ngFor="let type of customerTypes; let i = index"
                id="item-{{ i + 1 }}"
                role="tabpanel"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-12 text-start">
                      <h3 class="text-dark hs-title-5">
                        {{ type.customerType }}
                      </h3>
                    </div>
                    <hr />

                    <!-- Add corporate logos here -->
                    <div
                      *ngFor="
                        let customer of customers
                          | filterCustomersByType : type.customerType
                      "
                      class="col-lg-2 col-md-4 col-sm-6 col-6 mb-4"
                    >
                      <img
                        [src]="this.httpService.fileUrl(customer.desktopFile)"
                        [alt]="type.customerType"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Tab panes -->
          </div>
        </div>
      </div>
    </section>

    <!-- Section -->
    <section class="page-section pt-0" *ngIf="peoples.length > 0">
      <div class="container relative">
        <!-- <h2 class="text-center mb-5 hs-title-4 text-dark-gray">
          Team behind Our Success
        </h2> -->
        <!-- Logo Grid -->
        <div class="row alt-features-grid gx-5">
          <!-- Logo Item -->
          <div
            *ngFor="let people of peoples; let i = index"
            class="col-sm-6 col-md-4 col-lg-4"
          >
            <div
              class="alt-features-item mt-50 wow fadeIn"
              data-wow-delay="0"
              data-wow-duration="1.5s"
            >
              <div class="mb-20">
                <img
                  [src]="this.httpService.fileUrl(people.desktopFile)"
                  [alt]="people.name"
                />
              </div>
              <h4 class="mb-2">{{ people.name }}</h4>
              <h5>{{ people.position }}</h5>
              <div
                class="alt-features-descr align-left"
                style="text-align: justify"
              >
                {{ people.description }}
              </div>
            </div>
          </div>
          <!-- End Logo Item -->
        </div>
        <!-- End Logo Grid -->
      </div>
    </section>
    <!-- End Section -->

    <!-- About Section -->
    <section class="page-section pt-0" *ngIf="downloads.length > 0">
      <div class="container accordian-padding-resources">
        <div class="row section-text">
          <div class="col-md-12 mb-sm-20">
            <!-- Toggle -->
            <dl class="toggle">
              <ng-container *ngFor="let download of downloads; let i = index">
                <ng-container *ngIf="download.files.length > 0">
                  <dt>
                    <a href="javascript:void(0)">{{ download.title }}</a>
                  </dt>
                  <dd>
                    <div class="mt-4 accordian-data">
                      <div class="row">
                        <div
                          *ngFor="let file of download.files"
                          class="col-lg-5 col-md-12 col-sm-12 mb-3"
                        >
                          <div
                            class="row bg-gray-light-1 py-4 align-items-center gx-3"
                          >
                            <div class="col-8">
                              <div
                                class="ms-3 font-weight-bold reso-heading-text"
                              >
                                {{ file.title }}
                              </div>
                            </div>

                            <div class="col-4 text-end button-link">
                              <a
                                [href]="
                                  this.httpService.fileUrl(file.desktopFile)
                                "
                                target="_blank"
                              >
                                <img
                                  src="assets/images/eye.svg"
                                  alt="View Icon"
                                  class="img-fluid"
                                />
                              </a>
                              <a
                                (click)="
                                  downloadPdf(
                                    this.httpService.fileUrl(file.desktopFile),
                                    file.title + '.pdf'
                                  )
                                "
                                href="javascript:void(0)"
                              >
                                <img
                                  src="assets/images/download.svg"
                                  alt="Download Icon"
                                  class="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                </ng-container>
              </ng-container>
            </dl>
            <!-- End Toggle -->
          </div>
        </div>
      </div>
    </section>

    <!-- Divider -->
    <hr class="mt-0 mb-0" *ngIf="downloads.length > 0" />
    <!-- End Divider -->

    <!-- FAQ Section -->
    <section class="page-section z-index-1 pt-0" *ngIf="faqs.length > 0">
      <div class="container position-relative">
        <div class="row gx-5 position-relative">
          <div class="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.4s">
            <!-- Accordion -->
            <dl class="toggle faqs">
              <ng-container *ngFor="let faq of faqsLeft; let i = index">
                <dt>
                  <a href="javascript:void(0)">{{ faq.question }}</a>
                </dt>
                <dd>
                  <div
                    class="pt-3 pb-3"
                    [innerHtml]="sanitizeHtml(faq.answer)"
                  ></div>
                </dd>
              </ng-container>
            </dl>
            <!-- End Accordion -->
          </div>
          <div class="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.5s">
            <!-- Accordion -->
            <dl class="toggle faqs">
              <ng-container *ngFor="let faq of faqsRight; let i = index">
                <dt>
                  <a href="javascript:void(0)">{{ faq.question }}</a>
                </dt>
                <dd>
                  <div
                    class="pt-3 pb-3"
                    [innerHtml]="sanitizeHtml(faq.answer)"
                  ></div>
                </dd>
              </ng-container>
            </dl>
            <!-- End Accordion -->
          </div>
        </div>
      </div>
    </section>
    <!-- End FAQ Section -->

    <!-- Uncarbon FAQ Section -->
    <section
      class="page-section z-index-1 pt-0"
      *ngIf="uncarbonFaqs.length > 0"
    >
      <div class="container position-relative">
        <div class="row gx-5 position-relative">
          <div class="col-md-6 col-lg-6 wow fadeInLeft" data-wow-delay="0.4s">
            <!-- Accordion -->
            <dl class="toggle faqs">
              <ng-container *ngFor="let faq of uncarbonFaqsLeft; let i = index">
                <dt>
                  <a href="javascript:void(0)">{{ faq.question }}</a>
                </dt>
                <dd>
                  <div
                    class="pt-3 pb-3"
                    [innerHtml]="sanitizeHtml(faq.answer)"
                  ></div>
                </dd>
              </ng-container>
            </dl>
            <!-- End Accordion -->
          </div>

          <div class="col-md-6 col-lg-6 wow fadeInRight" data-wow-delay="0.5s">
            <!-- Accordion -->
            <dl class="toggle faqs">
              <ng-container
                *ngFor="let faq of uncarbonFaqsRight; let i = index"
              >
                <dt>
                  <a href="javascript:void(0)">{{ faq.question }}</a>
                </dt>
                <dd>
                  <div
                    class="pt-3 pb-3"
                    [innerHtml]="sanitizeHtml(faq.answer)"
                  ></div>
                </dd>
              </ng-container>
            </dl>
            <!-- End Accordion -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Uncarbon FAQ Section -->

    <section class="page-section pt-0" *ngIf="investors.length > 0">
      <div class="container accordian-padding-resources">
        <div class="row section-text">
          <div class="col-md-12 mb-sm-20">
            <!-- Toggle -->
            <dl class="toggle">
              <ng-container *ngFor="let investor of investors; let i = index">
                <ng-container *ngIf="investor.files.length > 0">
                  <dt>
                    <a href="javascript:void(0)">{{ investor.title }}</a>
                  </dt>
                  <dd>
                    <div class="mt-4 accordian-data">
                      <div class="row">
                        <div
                          *ngFor="let file of investor.files"
                          class="col-lg-5 col-md-12 col-sm-12 mb-3"
                        >
                          <div
                            class="row bg-gray-light-1 py-4 align-items-center gx-3"
                          >
                            <div class="col-8">
                              <div
                                class="ms-3 font-weight-bold reso-heading-text"
                                [innerHTML]="file.title"
                              ></div>
                              <div
                                class="ms-3 font-weight-bold reso-heading-text"
                                *ngIf="file.subTitle"
                              >
                                {{ file.subTitle }}
                              </div>
                            </div>

                            <div
                              class="col-4 text-end button-link"
                              *ngIf="file.desktopFile"
                            >
                              <a
                                [href]="
                                  this.httpService.fileUrl(file.desktopFile)
                                "
                                target="_blank"
                              >
                                <img
                                  src="assets/images/eye.svg"
                                  alt="View Icon"
                                  class="img-fluid"
                                />
                              </a>
                              <a
                                (click)="
                                  downloadPdf(
                                    this.httpService.fileUrl(file.desktopFile),
                                    file.title + '.pdf'
                                  )
                                "
                                href="javascript:void(0)"
                              >
                                <img
                                  src="assets/images/download.svg"
                                  alt="Download Icon"
                                  class="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                </ng-container>
              </ng-container>
            </dl>
            <!-- End Toggle -->
          </div>
        </div>
      </div>
    </section>

    <!-- Divider -->
    <hr class="mt-0 mb-0" *ngIf="investors.length > 0" />
    <!-- End Divider -->

    <!-- Address Section -->
    <section class="page-section z-index-1 pt-0" *ngIf="address.length > 0">
      <div class="container position-relative">
        <div class="row position-relative">
          <div
            *ngFor="let add of address"
            class="col-sm-12 col-md-6 col-lg-4 bg-gray-light-1 contact-border-box"
          >
            <div
              class="mt-3 wow fadeInUp"
              data-wow-delay="0"
              data-wow-duration="1s"
            >
              <div>
                <iframe
                  [src]="iframeUrl(add.officeMap)"
                  width="100%"
                  height="230"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </div>
              <div class="p-3">
                <div
                  class="wow fadeScaleIn"
                  data-wow-delay="0"
                  data-wow-duration="1s"
                >
                  <h4 class="">{{ add.officeName }}</h4>
                  <!-- <div class="row">
                    <div class="col-lg-6 mobile-column">
                      <h4 class="ci-title text-secondary fs-6">Phone:</h4>
                      <div class="ci-text text-black fs-6">
                        <ng-container *ngFor="let phone of add.phone; let i = index">
                          <a [href]="'tel:' + phone">{{ phone }}</a>
                          <span *ngIf="i < add.phone.length - 1"> <br /> </span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h4 class="ci-title text-secondary fs-6">Mobile No:</h4>
                      <div class="ci-text text-black fs-6">
                        <ng-container *ngFor="let phone of add.mobile; let i = index">
                          <a [href]="'tel:' + phone">{{ phone }}</a>
                          <span *ngIf="i < add.mobile.length - 1"> <br /> </span>
                        </ng-container>
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-lg-6 mobile-column">
                      <h4 class="ci-title text-secondary fs-6">Phone:</h4>
                      <div class="ci-text text-black fs-6">
                        <ng-container
                          *ngFor="let phone of add.phone; let i = index"
                        >
                          <a [href]="'tel:' + phone">{{ phone.trim() }}</a>
                          <br *ngIf="i === 0 && add.phone.length > 1" />
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <h4 class="ci-title text-secondary fs-6">Mobile No:</h4>
                      <div class="ci-text text-black fs-6">
                        <ng-container
                          *ngFor="let mobile of add.mobile; let i = index"
                        >
                          <a [href]="'tel:' + mobile">{{ mobile.trim() }}</a>
                          <br *ngIf="i === 0 && add.mobile.length > 1" />
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="row mobile-email-container">
                    <div class="col-lg-12 email-column">
                      <h4 class="ci-title text-secondary fs-6">Email:</h4>
                      <div class="ci-text text-black fs-6">
                        <a [href]="'mailto:' + add.email">{{ add.email }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <h4 class="ci-title text-secondary fs-6">Address:</h4>
                    <div
                      class="ci-text text-black fs-6 content-desc"
                      [innerHtml]="sanitizeHtml(add.address)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Address Section -->

    <ng-container *ngFor="let city of getCityKeys(); let i = index">
      <section class="page-section" [ngClass]="{ 'pt-0': i == 0 }">
        <div class="container position-relative">
          <div class="row">
            <h3 class="hs-title-6 uppercase mb-4">{{ city }}</h3>
            <div class="col-lg-5" *ngFor="let dealer of getDealers(city)">
              <div class="">
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-location fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      {{ dealer.name }}
                      <br />
                      {{ dealer.address }}
                    </p>
                  </div>
                </div>
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-mobile fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      <span class="text-secondary">Phone:</span><br />
                      <ng-container
                        *ngFor="let phone of dealer.phone; let i = index"
                      >
                        <a [href]="'tel:' + phone">{{ phone }}</a>
                        <span *ngIf="i < dealer.phone.length - 1"> | </span>
                      </ng-container>
                    </p>
                  </div>
                </div>
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-email fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      <span class="text-secondary">Email:</span><br />
                      <a [href]="'mailto:' + dealer.email">{{
                        dealer.email
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 divider-column"
              *ngIf="getRemainingDealers(city).length > 0"
            >
              <div class="divider"></div>
            </div>
            <div
              class="col-lg-5"
              *ngFor="let dealer of getRemainingDealers(city)"
            >
              <div class="">
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-location fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      {{ dealer.name }}
                      <br />
                      {{ dealer.address }}
                    </p>
                  </div>
                </div>
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-mobile fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      <span class="text-secondary">Phone:</span><br />
                      <ng-container
                        *ngFor="let phone of dealer.phone; let i = index"
                      >
                        <a [href]="'tel:' + phone">{{ phone }}</a>
                        <span *ngIf="i < dealer.phone.length - 1"> | </span>
                      </ng-container>
                    </p>
                  </div>
                </div>
                <div class="contact-info">
                  <div class="text-white rounded-circle border">
                    <i class="mi-email fs-4 p-2 text-dark"></i>
                  </div>
                  <div>
                    <p>
                      <span class="text-secondary">Email:</span><br />
                      <a [href]="'mailto:' + dealer.email">{{
                        dealer.email
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Divider -->
      <hr *ngIf="getCityKeys().length > 1" />
      <!-- End Divider -->
    </ng-container>

    <section class="page-section z-index-1 pt-0" *ngIf="jobs.length > 0">
      <div class="container position-relative">
        <div class="row mb-n30">
          <div class="text-center mb-60 mb-sm-40">
            <h3 class="hs-title-5 mb-2">Current Job Offers and Vacancies</h3>
            <p
              class="section-descr mb-0 wow fadeIn"
              data-wow-delay="0.2s"
              data-wow-duration="1.2s"
            >
              Join us and be part of a growing industry
            </p>
          </div>
          <!-- Services Item-->
          <div
            *ngFor="let job of jobs; let i = index"
            class="col-md-6 col-lg-4 d-flex align-items-stretch mb-30"
          >
            <div class="p-4 p-sm-5 border round">
              <div class="wow fadeScaleIn" data-wow-delay="0.1s">
                <h4 class="mb-2">{{ job.title }}</h4>
                <p>
                  {{ job.description }}
                </p>
                <!-- Divider -->
                <hr class="my-3" />
                <!-- End Divider -->
                <p>
                  Experience: {{ job.experience }}<br />
                  Qualification: {{ job.qualification }}<br />
                  Post: {{ job.post }}
                </p>

                <div class="my-3">
                  <a
                    (click)="applyNow(job._id)"
                    class="btn btn-mod btn-large btn-hover-anim"
                    ><span>Apply Now</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- End Services Item-->
        </div>
      </div>
    </section>

    <section class="page-section z-index-1 pt-0" *ngIf="applyNowPage">
      <div class="container position-relative job-form-w">
        <form [formGroup]="jobForm" (ngSubmit)="onSubmitJobForm()">
          <div class="row mb-3">
            <div class="text-center">
              <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <h3 class="hs-title-4 mb-2">Apply for Job</h3>
                <p
                  class="section-descr wow fadeIn"
                  data-wow-delay="0.2s"
                  data-wow-duration="1.2s"
                >
                  At VYARA, we are constantly looking for committed,
                  enthusiastic, well motivated people on long term basis.
                </p>
              </div>
            </div>
          </div>

          <!-- Name Input Section -->
          <div class="row mb-2">
            <div class="col-lg-4 mb-3">
              <div class="">
                <label for="surName" class="fs-6"
                  >Surname<span class="fs-4 text-danger">*</span></label
                >
                <input
                  type="text"
                  formControlName="surName"
                  id="surName"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <div class="">
                <label for="firstName" class="fs-6"
                  >First Name<span class="fs-4 text-danger">*</span></label
                >
                <input
                  type="text"
                  formControlName="firstName"
                  id="firstName"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <label for="fatherName" class="fs-6">Father's Name</label>
              <div class="">
                <input
                  type="text"
                  formControlName="fatherName"
                  id="fatherName"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6 mb-sm-20">
              <label for="phone" class="fs-6"
                >Mobile No. 1<span class="fs-4 text-danger">*</span></label
              >
              <div class="">
                <input
                  type="number"
                  formControlName="phone"
                  id="phone"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label for="phone1" class="fs-6">Mobile No. 2</label>
              <div class="">
                <input
                  type="number"
                  formControlName="phone1"
                  id="phone1"
                  class="input-lg"
                  placeholder=""
                  maxlength="100"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <label for="permanentAddress" class="fs-6"
              >Permanent Address<span class="fs-4 text-danger">*</span></label
            >
            <div class="">
              <textarea
                formControlName="permanentAddress"
                id="permanentAddress"
                rows="1"
                class="input-lg"
                placeholder=""
              ></textarea>
            </div>
          </div>

          <div class="row mb-3">
            <label for="persentAddress" class="fs-6"
              >Present Address<span class="fs-4 text-danger">*</span></label
            >

            <div class="">
              <textarea
                formControlName="persentAddress"
                id="persentAddress"
                rows="1"
                class="input-lg"
                placeholder=""
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label for="email" class="fs-6"
              >Email<span class="fs-4 text-danger">*</span></label
            >
            <div class="">
              <input
                type="email"
                formControlName="email"
                id="email"
                class="input-lg"
                placeholder=""
                maxlength="100"
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6 mb-sm-20">
              <label for="dob" class="fs-6"
                >Date of Birth<span class="fs-4 text-danger">*</span></label
              >
              <div class="">
                <input
                  type="date"
                  formControlName="dob"
                  id="dob"
                  class="input-lg"
                  placeholder="DD/MM/YYYY"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label for="placeBirth" class="fs-6">Place of Birth</label>
              <div class="">
                <input
                  type="text"
                  formControlName="placeBirth"
                  id="placeBirth"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label for="Number" class="fs-6 mb-2"
                >Upload CV<span class="fs-4 text-danger">*</span></label
              >
              <div class="w-100 attach-cv">
                <input
                  type="file"
                  id="actual-btn"
                  hidden
                  (change)="onFileChange($event)"
                />
                <!-- our custom upload button -->
                <label for="actual-btn">Choose File</label>
                <!-- name of file chosen -->
                <span id="file-chosen">{{ fileName || "No file chosen" }}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <h4 class="text-uppercase text-center mt-4 mb-4">
              Personal Details
            </h4>
            <div class="mb-4">
              <!-- Row -->
              <div>
                <h5>Languages Known ( Please Tick )</h5>
                <div class="table-container">
                  <table class="">
                    <thead>
                      <tr class="lang-row">
                        <td class="py-3 px-3">Language</td>
                        <td class="py-3 text-center">Speak</td>
                        <td class="py-3 text-center">Read</td>
                        <td class="py-3 text-center">Write</td>
                      </tr>
                    </thead>
                    <tbody formArrayName="languages">
                      <tr
                        class="lang-row"
                        *ngFor="
                          let language of languages.controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <td class="py-2 px-3">
                          <input
                            type="text"
                            formControlName="language"
                            id="language"
                            class="input-lg"
                            placeholder="Enter Language {{ i + 1 }}"
                          />
                        </td>
                        <td class="p-0 position-relative">
                          <label
                            class="container-checkbox job-checkbox-1 position-absolute"
                          >
                            <input type="checkbox" formControlName="speak" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td class="p-0 position-relative">
                          <label
                            class="container-checkbox job-checkbox-1 position-absolute"
                          >
                            <input type="checkbox" formControlName="read" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td class="p-0 position-relative">
                          <label
                            class="container-checkbox job-checkbox-1 position-absolute"
                          >
                            <input type="checkbox" formControlName="write" />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
            </div>

            <div class="mb-4">
              <h5>Educational Qualifications</h5>
              <!-- Row -->
              <div class="pt-1">
                <div class="table-container">
                  <table class="lang-table">
                    <thead>
                      <tr>
                        <td class="py-2">Examination passed(SSC onwards)</td>
                        <td class="py-2">Institute or College where studied</td>
                        <td class="py-2">Board / Uni. Conducting the Exam.</td>
                        <td class="py-2">Principal Subjects</td>
                        <td class="py-2">Year of Passing</td>
                        <td class="py-2">Class Obtained</td>
                        <td class="py-2">% age of Marks</td>
                      </tr>
                    </thead>
                    <tbody formArrayName="educations">
                      <tr
                        class="lang-row"
                        *ngFor="
                          let education of educations.controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <td>
                          <input
                            type="text"
                            formControlName="examination"
                            id="examination"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="institute"
                            id="institute"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="board"
                            id="board"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="subject"
                            id="subject"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="year"
                            id="year"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="class"
                            id="class"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="percentage"
                            id="percentage"
                            class="input-lg"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- End Row -->
            </div>

            <div class="mb-4">
              <h5>Experience ( Most Recent Job First )</h5>
              <!-- Row -->
              <div class="pt-1">
                <div class="table-container">
                  <table class="lang-table">
                    <thead>
                      <tr class="lang-row">
                        <td class="py-3">Name of the Company</td>
                        <td class="py-3">Designation</td>
                        <td class="py-3">From</td>
                        <td class="py-3">To</td>
                        <td class="py-3">Gross Salary</td>
                        <td class="py-3">Nature of Duties</td>
                      </tr>
                    </thead>
                    <tbody formArrayName="experiences">
                      <tr
                        class="lang-row"
                        *ngFor="
                          let experience of experiences.controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <td>
                          <input
                            type="text"
                            formControlName="companyName"
                            id="companyName"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="designation"
                            id="designation"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="from"
                            id="from"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="to"
                            id="to"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="salary"
                            id="salary"
                            class="input-lg"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            formControlName="duties"
                            id="duties"
                            class="input-lg"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- End Row -->
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-6">
              <label for="presentSalary" class="fs-6"
                >Present Salary<span class="fs-4 text-danger">*</span></label
              >
              <div class="">
                <input
                  type="number"
                  formControlName="presentSalary"
                  id="presentSalary"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label for="expectedSalary" class="fs-6"
                >Expected Salary<span class="fs-4 text-danger">*</span></label
              >
              <div class="">
                <input
                  type="number"
                  formControlName="expectedSalary"
                  id="expectedSalary"
                  class="input-lg"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <!-- button -->
          <div class="text-end">
            <!-- Send Button -->
            <div class="pt-20 pb-50">
              <button
                [disabled]="jobForm.invalid"
                type="submit"
                class="submit_btn btn btn-mod btn-large btn-round btn-hover-anim"
                id="submit_btn"
                aria-controls="result"
              >
                <span>APPLY</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>

    <!-- Enquiry Form -->
    <section class="page-section z-index-1 pt-0" *ngIf="inquiryNowPage">
      <div class="container position-relative">
        <div class="row position-relative">
          <div class="col-md-8 col-lg-8 mx-auto pt-10 pt-sm-0">
            <!-- Contact Form -->
            <form
              [formGroup]="contactForm"
              (ngSubmit)="onSubmitContactForm()"
              class="form contact-form pe-lg-5"
              id="contact_form"
            >
              <div class="row">
                <div class="col-lg-6">
                  <!-- Name -->
                  <div class="form-group">
                    <label for="fullName"
                      >Full Name <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="fullName"
                      id="fullName"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <!-- Mobile No -->
                  <div class="form-group">
                    <label for="mobile"
                      >Mobile Number
                      <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="number"
                      formControlName="mobile"
                      id="mobile"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <!-- Email -->
                  <div class="form-group">
                    <label for="email"
                      >Email <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 mb-3">
                  <label for="product"
                    >Product <span class="fs-4 text-danger">*</span></label
                  >
                </div>
                <div class="col-lg-6 inline-options text-gray">
                  <label
                    *ngFor="let product of productLeft"
                    class="container-checkbox"
                    >{{ product.productName }}
                    <input
                      type="checkbox"
                      [value]="product._id"
                      (change)="onCheckboxChange($event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-lg-6 inline-options text-gray">
                  <label
                    *ngFor="let product of productRight"
                    class="container-checkbox"
                    >{{ product.productName }}
                    <input
                      type="checkbox"
                      [value]="product._id"
                      (change)="onCheckboxChange($event)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- Address -->
              <div class="form-group">
                <label for="address"
                  >Address <span class="fs-4 text-danger">*</span></label
                >
                <input
                  type="text"
                  formControlName="address"
                  id="address"
                  class="input-lg round form-control"
                  placeholder=""
                />
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="address"
                      >City <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="city"
                      id="city"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="address"
                      >State <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="state"
                      id="state"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="address"
                      >Country <span class="fs-4 text-danger">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="country"
                      id="country"
                      class="input-lg round form-control"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <!-- Message -->
              <div class="form-group">
                <label for="message"
                  >Message <span class="fs-4 text-danger">*</span></label
                >
                <textarea
                  formControlName="message"
                  id="message"
                  class="input-lg round form-control"
                  style="height: 130px"
                  placeholder=""
                ></textarea>
              </div>

              <div class="row">
                <div class="col-lg-5">
                  <!-- Send Button -->
                  <div class="pt-20">
                    <button
                      [disabled]="contactForm.invalid"
                      type="submit"
                      class="submit_btn btn btn-mod btn-large btn-round btn-hover-anim"
                      id="submit_btn"
                      aria-controls="result"
                    >
                      <span>SEND ENQUIRY</span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                id="result"
                role="region"
                aria-live="polite"
                aria-atomic="true"
              ></div>
            </form>
            <!-- End Contact Form -->
          </div>
        </div>
      </div>
    </section>
    <!-- Enquiry Form Ends -->

    <ng-container *ngIf="appTypes && appTypes.length > 0">
      <section class="page-section home-projects">
        <div class="container overflow-hidden">
          <div class="row position-relative">
            <div class="wow charsAnimIn text-black text-left mb-20">
              <h1 class="hs-title-1 text-dark-gray">Gallery</h1>
              <p class="hs-title-6 text-gray mb-2">Projects we are proud of</p>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <!-- Nav Tabs -->
              <div class="wow fadeInLeft">
                <ul
                  class="nav nav-tabs tpl-tabs tab-left animate"
                  role="tablist"
                >
                  <!-- <li class="nav-item" role="presentation">
                    <a
                      href="#item-1"
                      aria-controls="item-1"
                      class="nav-link active"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="true"
                      >All</a
                    >
                  </li> -->
                  <li
                    class="nav-item"
                    role="presentation"
                    *ngFor="let type of appTypes; let i = index"
                  >
                    <a
                      [href]="'gallery/' + type.link"
                      [attr.aria-controls]="'item-' + (i + 2)"
                      class="nav-link"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      {{ type.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <!-- End Nav Tabs -->

              <!-- Tab panes -->
              <div class="tab-content mt-70 tpl-minimal-tabs-cont wow fadeInUp">
                <!-- <div
                  class="tab-pane fade active show"
                  id="item-1"
                  role="tabpanel"
                >
                  <div
                    class="row position-relative mt-n40 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <ng-container
                      *ngFor="let app of applications; let i = index"
                    >
                      <div
                        class="col-6 col-sm-6 col-lg-3 mb-20 product-item"
                        (click)="setActiveProduct(i)"
                      >
                        <div
                          class="product-menu-hover no-pm gallery-scroll"
                          [ngClass]="{
                            active: i === activeProduct
                          }"
                        >
                          <a
                            href="javascript:void(0)"
                            class="team-item pt-3 pb-3"
                          >
                            <div class="team-item-image">
                              <img
                                [src]="
                                  this.httpService.fileUrl(app?.desktopFile)
                                "
                                [alt]="app?.title"
                              />
                            </div>
                            <div class="team-item-descr text-start">
                              <div class="team-item-name">{{ app?.title }}</div>
                              <div
                                class="team-item-role"
                                *ngIf="app.applicationId?.title"
                              >
                                {{ app.applicationId?.title }}
                              </div>
                            </div>
                          </a>
                        </div>
                        <div
                          class="col-lg-12 full-gallery-view"
                          [ngStyle]="{
                            display: i === activeProduct ? 'block' : 'none'
                          }"
                        >
                          <div class="row position-relative mb-4">
                            <div class="col-lg-10 col-md-8">
                              <div class="wow linesAnimIn">
                                <h2 class="hs-title-3 text-dark">
                                  {{ app.subCategoryId?.subCategoryName }}
                                </h2>
                                <div
                                  class="product-desc text-gray justify"
                                  [innerHtml]="app.subCategoryId?.description"
                                ></div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-4">
                              <div class="pro-thumb wow linesAnimIn">
                                <img
                                  [src]="
                                    this.httpService.fileUrl(
                                      app.subCategoryId?.sampleFile
                                    )
                                  "
                                  class="w-100"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-9">
                              <div
                                class="position-relative mb-20 pd-gallery-box"
                              >
                                <div class="pd-slider1 bg-dark light-content">
                                  <section
                                    class="home-section bg-scroll bg-dark-alpha-60 light-content"
                                    *ngIf="app.desktopFile"
                                  >
                                    <div class="work-img-bg post-prev-img">
                                      <a
                                        [href]="
                                          this.httpService.fileUrl(
                                            app.desktopFile
                                          )
                                        "
                                        class="lightbox mfp-image"
                                      >
                                        <img
                                          class="w-100"
                                          [src]="
                                            this.httpService.fileUrl(
                                              app.desktopFile
                                            )
                                          "
                                        />
                                      </a>
                                    </div>
                                    <div>
                                      <div
                                        class="col-md-10 w-100 product-details-text-overlay"
                                      >
                                        <p class="pd-text mb-0">
                                          {{ app?.title }}
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                                <div
                                  class="slider-counter slider-counting-product-details"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="col-lg-3 d-flex align-items-center"
                              *ngIf="
                                app.subCategoryId.contents &&
                                app.subCategoryId.contents.length > 0
                              "
                            >
                              <dl class="toggle">
                                <ng-container
                                  *ngFor="
                                    let content of app.subCategoryId.contents;
                                    let i = index
                                  "
                                >
                                  <dt>
                                    <a href="javascript:void(0)">{{
                                      content.title
                                    }}</a>
                                  </dt>
                                  <dd>
                                    <div class="py-3">
                                      <div
                                        class="content-desc"
                                        [innerHtml]="content.description"
                                      ></div>
                                    </div></dd
                                ></ng-container>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div> -->
                <div
                  *ngFor="let type of appTypes; let i = index"
                  class="tab-pane fade"
                  role="tabpanel"
                  id="item-{{ i + 2 }}"
                >
                  <div
                    class="row position-relative mt-n40 wow fadeInUp"
                    data-wow-delay="0.1s"
                    *ngIf="applications.length > 0"
                  >
                    <ng-container
                      *ngFor="
                        let app of applications
                          | filterApplicationByType : type.title;
                        let i = index
                      "
                    >
                      <div
                        class="col-6 col-sm-6 col-lg-3 mb-20 product-item"
                        (click)="setActiveProduct(i)"
                      >
                        <div
                          class="product-menu-hover no-pm gallery-scroll"
                          [ngClass]="{
                            active: i === activeProduct
                          }"
                        >
                          <a
                            href="javascript:void(0)"
                            class="team-item pt-3 pb-3"
                          >
                            <div class="team-item-image">
                              <img
                                [src]="
                                  this.httpService.fileUrl(app.desktopFile)
                                "
                                [alt]="app.title"
                              />
                            </div>
                            <div class="team-item-descr text-start">
                              <div class="team-item-name">{{ app.title }}</div>
                              <div
                                class="team-item-role"
                                *ngIf="app?.applicationId?.title"
                              >
                                {{ app.applicationId.title }}
                              </div>
                            </div>
                          </a>
                        </div>
                        <div
                          class="col-lg-12 full-gallery-view"
                          [ngStyle]="{
                            display: i === activeProduct ? 'block' : 'none'
                          }"
                        >
                          <div class="row position-relative mb-4">
                            <div class="col-lg-10 col-md-8">
                              <div class="">
                                <h2 class="hs-title-3 text-dark">
                                  {{ app.subCategoryId?.subCategoryName }}
                                </h2>
                                <div
                                  class="product-desc text-gray justify"
                                  [innerHtml]="
                                    sanitizeHtml(app.subCategoryId?.description)
                                  "
                                ></div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-4">
                              <div class="pro-thumb">
                                <img
                                  *ngIf="app.subCategoryId.sampleFile"
                                  [src]="
                                    this.httpService.fileUrl(
                                      app.subCategoryId?.sampleFile
                                    )
                                  "
                                  class="w-100"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-9">
                              <div
                                class="position-relative mb-20 pd-gallery-box"
                              >
                                <div class="pd-slider1 bg-dark light-content">
                                  <section
                                    class="home-section bg-scroll bg-dark-alpha-60 light-content"
                                  >
                                    <div class="work-img-bg post-prev-img">
                                      <a
                                        [href]="
                                          this.httpService.fileUrl(
                                            app.desktopFile
                                          )
                                        "
                                        class="lightbox mfp-image"
                                        [title]="app?.title"
                                      >
                                        <img
                                          class="w-100"
                                          [src]="
                                            this.httpService.fileUrl(
                                              app.desktopFile
                                            )
                                          "
                                        />
                                      </a>
                                    </div>
                                    <div class="">
                                      <div
                                        class="col-md-10 w-100 product-details-text-overlay"
                                      >
                                        <p class="pd-text mb-0">
                                          {{ app?.title }}
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                                <div
                                  class="slider-counter slider-counting-product-details"
                                ></div>
                              </div>
                            </div>
                            <div
                              class="col-lg-3 d-flex align-items-center"
                              *ngIf="app.subCategoryId?.contents.length > 0"
                            >
                              <dl class="toggle">
                                <ng-container
                                  *ngFor="
                                    let content of app.subCategoryId.contents;
                                    let i = index
                                  "
                                >
                                  <dt>
                                    <a href="javascript:void(0)">{{
                                      content.title
                                    }}</a>
                                  </dt>
                                  <dd>
                                    <div class="py-3">
                                      <div
                                        class="content-desc"
                                        [innerHtml]="
                                          sanitizeHtml(content.description)
                                        "
                                      ></div>
                                    </div></dd
                                ></ng-container>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- <router-outlet></router-outlet> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </main>
  <app-footer></app-footer>
</div>
<!-- End Page Wrap -->
