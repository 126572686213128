import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProjectsByType',
  standalone: true,
})
export class FilterProjectsByTypePipe implements PipeTransform {
  transform(projects: any[], projectType: string): any[] {
    if (!projects || !projectType) {
      return [];
    }

    // Filter projects based on the project type
    return projects.filter(
      (project) => project.projectTypeId.projectType === projectType
    );
  }
}
