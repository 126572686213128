import { Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../http.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-project-details',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule,
  ],
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.css',
})
export class ProjectDetailsComponent {
  projectLink = '';
  project: any = {};
  projectGallery: any[] = [];
  rowItems: any[][] = [];

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title, // Inject Title service for setting title
    private metaService: Meta // Inject Meta service for setting meta tags,
  ) {}

  ngOnInit(): void {
    const projectLink = this.route.snapshot.paramMap.get('projectLink');
    this.projectLink = projectLink || '';
    if (this.projectLink) {
      this.getProject();
    }
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getProject() {
    this.httpService
      .PostAPI('project/getProjectByUrl', { projectLink: this.projectLink })
      .subscribe(
        (resdata: any) => {
          if (resdata.isSuccess) {
            this.project = resdata.data;
            this.projectGallery = resdata.data.projectGallery;
            this.updateMetaTags(
              this.project.metaTitle,
              this.project.metaDescription,
              this.project.metaKeyword
            );
          } else {
            this.router.navigateByUrl('/redirect-page');
          }
        },
        (err) => {
          this.router.navigateByUrl('/redirect-page');
        }
      );
  }

  updateMetaTags(title: string, description: string, keywords: string): void {
    // Set the document title
    this.titleService.setTitle(title);

    // Update meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }
}
