import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { CmsComponent } from './cms/cms.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { SubCategoryDetailsComponent } from './sub-category-details/sub-category-details.component';
import { AppGalleryComponent } from './app-gallery/app-gallery.component';
import { RedirectGuard } from './redirect.guard';
import { RedirectComponent } from './redirect/redirect.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'products/:productLink',
    component: ProductDetailsComponent,
    children: [
      {
        path: ':categoryLink',
        component: CategoryDetailsComponent,
        children: [
          { path: ':subCategoryLink', component: SubCategoryDetailsComponent },
        ],
      },
    ],
  },
  { path: 'projects', component: ProjectsComponent },
  { path: 'project/:projectLink', component: ProjectDetailsComponent },
  {
    path: 'gallery/:appLink',
    component: AppGalleryComponent,
  },
  {
    path: 'redirect-page',
    component: RedirectComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: ':menuUrl',
    component: CmsComponent,
  },
  {
    path: '**',
    redirectTo: '/redirect-page',
  },
];
