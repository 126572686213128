import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DataSharingService } from '../data-sharing.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [
    FooterComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    RouterOutlet,
  ],
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit {
  @ViewChild('scrollTarget', { static: false }) scrollTarget!: ElementRef;
  productLink = '';
  product: any = {};
  active_category: any = {};
  active_sub_category: any = {};
  productCategories: any[] = [];
  directSubCategories: any[] = [];
  activeCategory: string = '';
  activeSubCategory: string = '';
  categoryData: any = {};
  subCategoryData: any = {};

  public isExpanded: boolean = false;

  get productDescription(): string {
    if (this.isExpanded || !this.product.description) {
      return this.product.description;
    }
    return (
      this.product.description.slice(0, 375) +
      (this.product.description.length > 375 ? '...' : '')
    );
  }

  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }

  get showReadMoreButton(): boolean {
    return this.product.description && this.product.description.length > 375;
  }

  constructor(
    public router: Router,
    private toastr: ToastrService,
    public httpService: HttpService,
    private route: ActivatedRoute,
    private dataService: DataSharingService,
    private sanitizer: DomSanitizer,
    private titleService: Title, // Inject Title service for setting title
    private metaService: Meta, // Inject Meta service for setting meta tags,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.productLink = params.get('productLink') || '';
      if (this.productLink) {
        this.getProduct();
      }
    });
    this.dataService.currentData.subscribe((data) => {
      if (data) {
        if (data.categoryData) {
          this.categoryData = data.categoryData;
          this.updateMetaTags(
            this.categoryData.metaTitle,
            this.categoryData.metaDescription,
            this.categoryData.metaKeyword
          );
        }
        if (data.subCategoryData) {
          this.subCategoryData = data.subCategoryData;
          this.updateMetaTags(
            this.subCategoryData.metaTitle,
            this.subCategoryData.metaDescription,
            this.subCategoryData.metaKeyword
          );
        }
      }
    });
  }

  updateMetaTags(title: string, description: string, keywords: string): void {
    // Set the document title
    this.titleService.setTitle(title);

    // Update meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getProduct(): void {
    this.httpService
      .PostAPI('product/getProductByUrl', { productLink: this.productLink })
      .subscribe(
        (res: any) => {
          if (res.isSuccess) {
            this.product = res.data;
            this.updateMetaTags(
              this.product.metaTitle,
              this.product.metaDescription,
              this.product.metaKeyword
            );
            this.productCategories = res.data.productCategories || [];
            if (this.productCategories.length == 0) {
              this.directSubCategories = res.data.directSubCategories || [];
            }
            this.scrollToSection();
          } else {
            this.router.navigateByUrl('/redirect-page');
          }
        },
        (err) => {
          this.router.navigateByUrl('/redirect-page');
        }
      );
  }

  setActiveCategory(index: string): void {
    this.activeCategory = index;
    this.activeSubCategory = '';
  }

  setActiveSubCategory(index: string): void {
    this.activeSubCategory = index;
  }

  scrollToSection(): void {
    if (this.scrollTarget) {
      this.scrollToElement(this.scrollTarget.nativeElement);
    }
  }

  scrollToElement(target: HTMLElement): void {
    if (isPlatformBrowser(this.platformId)) {
      const targetOffsetTop = target.offsetTop - 50;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth',
      });
    }
  }
}
