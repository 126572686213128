<!-- Footer Desktop-->
<footer class="page-section footer-desktop bg-gray-light-3 pb-0">
  <div class="container">
    <div class="row pb-80 pb-sm-80 pb-xs-50">
      <div class="col-md-4 col-lg-3 text-gray mb-sm-50">
        <div class="mb-30" *ngIf="logo">
          <img
            [src]="this.httpService.fileUrl(logo)"
            height="40"
            alt="Your Company Logo"
          />
        </div>

        <span
          class="text-gray-light"
          *ngIf="address"
          [innerHtml]="address"
        ></span>

        <div class="clearlinks" *ngIf="phones.length > 0">
          <!-- <strong class="text-gray-light">T.</strong> -->
          <ng-container *ngFor="let phone of phones; let i = index">
            <a [href]="'tel:' + phone">{{ phone }}</a>
            <span *ngIf="i < phones.length - 1"> | </span>
          </ng-container>
        </div>

        <div class="clearlinks" *ngIf="email">
          <!-- <strong class="text-gray-light">E.</strong> -->
          <a [href]="'mailto:' + email">{{ email }}</a>
        </div>
        <div class="mt-3">
          <div class="d-flex fs-5 footer-social">
            <a
              *ngIf="instaLink"
              [href]="instaLink"
              rel="noopener nofollow"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-instagram" aria-hidden="true"></i>
            </a>
            <a
              *ngIf="fbLink"
              [href]="fbLink"
              rel="noopener nofollow"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-facebook-f" aria-hidden="true"></i>
            </a>
            <a
              *ngIf="linkedinLink"
              [href]="linkedinLink"
              rel="noopener nofollow"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-linkedin-in" aria-hidden="true"></i>
            </a>
            <a
              *ngIf="youtubeLink"
              [href]="youtubeLink"
              rel="noopener nofollow"
              target="_blank"
              class="text-decoration-none"
            >
              <i class="fa-youtube" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-8 col-lg-9" *ngIf="menus.length > 0">
        <div class="row">
          <!-- Footer Widget -->
          <ng-container *ngIf="products.length > 0">
            <div class="col-md-6 col-lg-3" *ngFor="let menu of products">
              <h3 class="fw-title">{{ menu.menuName }}</h3>
              <ng-container *ngIf="menu.subMenu.length > 0">
                <ul class="fw-menu clearlist" *ngFor="let s of menu.subMenu">
                  <li>
                    <a [href]="'products/' + s.productLink">{{
                      s.productName
                    }}</a>
                  </li>
                </ul>
              </ng-container>
            </div>
          </ng-container>
          <div class="col-md-6 col-lg-3" *ngIf="moreInfo.length > 0">
            <h3 class="fw-title">More Info</h3>
            <ul class="fw-menu clearlist">
              <li *ngFor="let menu of moreInfo">
                <!-- <a [href]="menu.menuUrl">
                  {{ menu.menuName }}
                </a> -->
                <a
                  [href]="
                    menu.menuName == 'Gallery'
                      ? 'gallery/bungalows-farmhouses'
                      : menu.menuUrl
                  "
                >
                  {{ menu.menuName }}
                </a>
              </li>
            </ul>
            <h3 class="fw-title mt-4" *ngIf="abouts[0].menuName">
              {{ abouts[0].menuName }}
            </h3>
            <ul class="fw-menu clearlist" *ngIf="abouts[0].subMenu.length > 0">
              <li *ngFor="let menu of abouts[0].subMenu">
                <a [href]="menu.menuUrl">
                  {{ menu.menuName }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-3" *ngFor="let menu of menus">
            <h3 class="fw-title">{{ menu.menuName }}</h3>
            <ul class="fw-menu clearlist">
              <li *ngFor="let sub of menu.subMenu">
                <a [href]="sub.menuUrl">
                  {{ sub.menuName }}
                </a>
              </li>
            </ul>
          </div>
          <!-- End Footer Widget -->
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white w-100">
    <div class="container">
      <!-- Footer Text -->
      <div class="row text-dark-gray py-3">
        <div class="col-md-12 col-lg-6">
          <div class="footer-logo">
            <img src="assets/images/footer-logo/logo1.png" />
            <img src="assets/images/footer-logo/logo2.png" />
            <img src="assets/images/footer-logo/logo3.png" />
            <img src="assets/images/footer-logo/logo4.png" />
          </div>
        </div>

        <div class="col-md-12 col-lg-6">
          <div class="copyright-text">
            <b
              >© Copyright 2025 Vyara Tiles Limited | Website developed by :
              <a href="https://www.setblue.com/" target="_blank"
                >Setblue.com</a
              ></b
            >
          </div>
        </div>
      </div>
      <!-- End Footer Text -->
    </div>
  </div>
</footer>
<!-- Back to Top Link -->
<div class="go-top local-scroll bottom-to-top">
  <a href="javascript:void(0)" (click)="scrollToTop()" class="link-to-top">
    <i class="mi-arrow-up size-24"></i>
    <span class="visually-hidden">Scroll to top</span>
  </a>
</div>
<!-- End Back to Top Link -->
<!-- End Footer -->

<!-- Footer Mobile -->
<footer class="page-section footer-mobile bg-gray-light-3 pb-0">
  <div class="container">
    <section class="pb-0 z-index-1">
      <div class="position-relative">
        <div class="row text-gray mb-sm-20">
          <div class="mb-30" *ngIf="logo">
            <img
              [src]="this.httpService.fileUrl(logo)"
              height="40"
              alt="Your Company Logo"
            />
          </div>
          <span
            class="text-gray-light"
            *ngIf="address"
            [innerHtml]="address"
          ></span>
          <div class="clearlinks">
            <!-- <strong class="text-gray-light">T.</strong> -->
            <ng-container *ngFor="let phone of phones; let i = index">
              <a [href]="'tel:' + phone">{{ phone }}</a>
              <span *ngIf="i < phones.length - 1"> | </span>
            </ng-container>
          </div>
          <div class="clearlinks">
            <!-- <strong class="text-gray-light">E.</strong> -->
            <a [href]="'mailto:' + email">{{ email }}</a>
          </div>

          <div class="mt-3">
            <div class="d-flex fs-5 footer-social">
              <a
                *ngIf="instaLink"
                [href]="instaLink"
                rel="noopener nofollow"
                target="_blank"
                class="text-decoration-none"
              >
                <i class="fa-instagram"></i>
              </a>
              <a
                *ngIf="fbLink"
                [href]="fbLink"
                rel="noopener nofollow"
                target="_blank"
                class="text-decoration-none"
              >
                <i class="fa-facebook-f"></i>
              </a>
              <a
                *ngIf="fbLink"
                [href]="fbLink"
                rel="noopener nofollow"
                target="_blank"
                class="text-decoration-none"
              >
                <i class="fa-linkedin-in"></i>
              </a>
              <a
                *ngIf="youtubeLink"
                [href]="youtubeLink"
                rel="noopener nofollow"
                target="_blank"
                class="text-decoration-none"
              >
                <i class="fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row position-relative mb-5">
          <div class="col offset-lg-1 pt-10 pt-sm-0">
            <!-- Accordion -->
            <dl class="toggle">
              <ng-container *ngFor="let menu of products; let i = index">
                <dt>
                  <a href="javascript:void(0)">{{ menu.menuName }}</a>
                </dt>
                <dd>
                  <ul class="fw-menu clearlist">
                    <li *ngFor="let s of menu.subMenu">
                      <a [href]="'products/' + s.productLink">{{
                        s.productName
                      }}</a>
                    </li>
                  </ul>
                </dd>
              </ng-container>
              <ng-container *ngIf="moreInfo.length > 0">
                <dt>
                  <a href="javascript:void(0)">More info</a>
                </dt>
                <dd>
                  <ul class="fw-menu clearlist">
                    <li *ngFor="let menu of moreInfo">
                      <a [href]="menu.menuUrl">
                        {{ menu.menuName }}
                      </a>
                    </li>
                  </ul>
                </dd>
              </ng-container>
              <ng-container *ngIf="abouts[0]?.menuName">
                <dt>
                  <a href="javascript:void(0)">{{ abouts[0]?.menuName }}</a>
                </dt>
                <dd>
                  <ul
                    class="fw-menu clearlist"
                    *ngIf="abouts[0]?.subMenu.length > 0"
                  >
                    <li *ngFor="let menu of abouts[0]?.subMenu">
                      <a [href]="menu.menuUrl" class="text-decoration-none">
                        {{ menu.menuName }}
                      </a>
                    </li>
                  </ul>
                </dd>
              </ng-container>
              <ng-container *ngFor="let menu of menus; let i = index">
                <dt>
                  <a href="javascript:void(0)">{{ menu.menuName }}</a>
                </dt>
                <dd>
                  <ul class="fw-menu clearlist" *ngIf="menu.subMenu.length > 0">
                    <li *ngFor="let sub of menu.subMenu">
                      <a [href]="sub.menuUrl">
                        {{ sub.menuName }}
                      </a>
                    </li>
                  </ul>
                </dd>
              </ng-container>
            </dl>
            <!-- End Accordion -->
          </div>
        </div>
      </div>
      <!-- Footer Text -->

      <!-- End Footer Text -->
    </section>
  </div>
  <div class="bg-white w-100">
    <div class="container">
      <div class="row text-gray">
        <div class="col-lg-4">
          <div class="footer-logo">
            <img src="assets/images/footer-logo/logo1.png" />
            <img src="assets/images/footer-logo/logo2.png" />
            <img src="assets/images/footer-logo/logo3.png" />
            <img src="assets/images/footer-logo/logo4.png" />
          </div>
        </div>

        <div class="col-lg-6 clearfix">
          <div class="copyright-text">
            <b
              >© Copyright 2024 Vyara Tiles Limited | Designed by:
              <a href="https://www.setblue.com/" target="_blank">Setblue</a></b
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->
