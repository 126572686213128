<div class="page" id="top">
  <app-header></app-header>

  <main id="main">
    <section class="page-section mt-70">
      <div class="container position-relative">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <div class="box-404">
              <img src="assets/images/404.png" />
              <h1 class="title-404 mb-20">404</h1>
              <div class="mb-40 mb-sm-30">
                <p class="section-descr mb-0">
                  The page you were looking for could not be found.
                </p>
              </div>

              <div class="local-scroll">
                <a
                  href="/"
                  class="btn btn-mod btn-w btn-round btn-medium btn-hover-anim"
                  ><i
                    class="mi-arrow-left size-24 align-center"
                    aria-hidden="true"
                  ></i>
                  <span>Back To Home Page</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer></app-footer>
  <app-freeform></app-freeform>
</div>
