import { Component } from '@angular/core';

@Component({
  selector: 'app-freeform',
  standalone: true,
  imports: [],
  templateUrl: './freeform.component.html',
  styleUrl: './freeform.component.css'
})
export class FreeformComponent {

}
