import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterApplicationByType',
  standalone: true,
})
export class FilterApplicationByTypePipe implements PipeTransform {
  transform(applications: any[], title: string): any[] {
    if (!applications || !title) {
      return [];
    }

    // Filter projects based on the project type
    return applications.filter(
      (application) => application.applicationId.title === title
    );
  }
}
