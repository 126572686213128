import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    LoaderComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  logo = '';
  fbLink = '';
  instaLink = '';
  linkedinLink = '';
  youtubeLink = '';
  menus: any[] = [];
  isHomePage = false;
  searchForm!: FormGroup;
  suggestions: any[] = [];
  searchInput = '';

  constructor(
    public toastr: ToastrService,
    public router: Router,
    public httpService: HttpService,
    private formBuilder: FormBuilder,
    private titleService: Title, // Inject Title service for setting title
    private metaService: Meta, // Inject Meta service for setting meta tags,
    private route: ActivatedRoute
  ) {
    this.searchForm = this.formBuilder.group({
      searchInput: [''],
    });
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (currentUrl === '/') {
      this.isHomePage = true;
    } else {
      this.isHomePage = false;
    }
    this.getSettings();
    this.getMenu();
    this.searchForm
      .get('searchInput')
      ?.valueChanges.pipe(
        debounceTime(300), // Debounce to avoid calling the API too frequently
        distinctUntilChanged() // Only trigger if the value has actually changed
      )
      .subscribe((value) => {
        if (value && value.length > 2) {
          this.onSearchInput(value);
        } else {
          this.suggestions = [];
        }
      });
  }

  onSearchInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const query = input.value;
    if (query.length > 2) {
      this.searchInput = query;
      const data = { searchInput: this.searchInput };
      this.httpService
        .PostAPI('productSubCategory/suggestions', data)
        .subscribe(
          (resdata: any) => {
            if (resdata.isSuccess) {
              this.suggestions = resdata.data;
            }
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        );
    } else {
      this.suggestions = [];
    }
  }

  onSearchClick() {
    // Handle search button click logic
    const query = this.searchForm.get('searchInput')?.value;
    if (query.length > 2) {
      this.searchInput = query;
      const data = { searchInput: this.searchInput };
      this.httpService
        .PostAPI('productSubCategory/suggestions', data)
        .subscribe(
          (resdata: any) => {
            if (resdata.isSuccess) {
              this.suggestions = resdata.data;
            }
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        );
    } else {
      this.suggestions = [];
    }
  }

  getSettings() {
    this.httpService.GetAPI('websiteSettings/getSettings').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          this.logo = resdata.data.desktopFile;
          this.fbLink = resdata.data.fbLink;
          this.instaLink = resdata.data.instaLink;
          this.linkedinLink = resdata.data.linkedinLink;
          this.youtubeLink = resdata.data.youtubeLink;
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  getMenu() {
    this.httpService.GetAPI('menu/getPublicMenu').subscribe(
      (resdata: any) => {
        if (resdata.isSuccess) {
          const menus = resdata.data;
          this.menus = menus.filter((menu: any) => menu.menuTemplate != 'Home');
          const currentRoute = this.route.snapshot.url.join('/');
          const currentPage = menus.find(
            (menu: any) => menu.menuUrl === currentRoute
          );
          if (!currentPage) {
            menus.forEach((menu: any) => {
              if (menu.subMenu && menu.subMenu.length) {
                const subMenuPage = menu.subMenu.find(
                  (subMenu: any) => subMenu.menuUrl === currentRoute
                );
                if (subMenuPage) {
                  this.updateMetaTags(
                    subMenuPage.metaTitle,
                    subMenuPage.metaDescription,
                    subMenuPage.metaKeyword
                  );
                }
              }
            });
          }
          if (currentPage) {
            this.updateMetaTags(
              currentPage.metaTitle,
              currentPage.metaDescription,
              currentPage.metaKeyword
            );
          }
        }
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  updateMetaTags(title: string, description: string, keywords: string): void {
    // Set the document title
    this.titleService.setTitle(title);

    // Update meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }
}
