import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '../http.service';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '../loader/loader.component';
import { FooterComponent } from '../footer/footer.component';
import { FreeformComponent } from '../freeform/freeform.component';
import { HeaderComponent } from '../header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { EventEmitter } from 'node:stream';
import { DataSharingService } from '../data-sharing.service';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sub-category-details',
  standalone: true,
  imports: [
    LoaderComponent,
    FooterComponent,
    FreeformComponent,
    HeaderComponent,
    ReactiveFormsModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
  ],
  templateUrl: './sub-category-details.component.html',
  styleUrl: './sub-category-details.component.css',
})
export class SubCategoryDetailsComponent implements OnInit {
  @ViewChild('scrollTarget1', { static: false }) scrollTarget1!: ElementRef;
  productLink!: string;
  categoryLink!: string;
  subCategoryLink!: string;
  subCategory: any = {};
  public isSubCategoryExpanded: boolean = false;

  constructor(
    private toastr: ToastrService,
    public httpService: HttpService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId1: Object,
    private dataService: DataSharingService,
    private sanitizer: DomSanitizer,
    private titleService: Title, // Inject Title service for setting title
    private metaService: Meta // Inject Meta service for setting meta tags,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.subCategoryLink = params.get('subCategoryLink') || '';
      this.categoryLink =
        this.route.parent?.snapshot.paramMap.get('categoryLink') || '';
      this.productLink =
        this.route.parent?.parent?.snapshot.paramMap.get('productLink') || '';
      // Load category details based on categoryLink
      this.fetchSubCategoryDetails(
        this.productLink,
        this.categoryLink,
        this.subCategoryLink
      );
    });
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  get subCategoryDescription(): string {
    if (this.isSubCategoryExpanded || !this.subCategory.description) {
      return this.subCategory.description;
    }
    return (
      this.subCategory.description.slice(0, 375) +
      (this.subCategory.description.length > 375 ? '...' : '')
    );
  }

  toggleSubCategoryReadMore() {
    this.isSubCategoryExpanded = !this.isSubCategoryExpanded;
  }

  get showSubCategoryReadMoreButton(): boolean {
    return (
      this.subCategory.description && this.subCategory.description.length > 375
    );
  }

  fetchSubCategoryDetails(
    productLink: string,
    categoryLink: string,
    subCategoryLink: string
  ) {
    this.httpService
      .PostAPI('productSubCategory/getSubCategoryByUrl', {
        subCategoryLink: subCategoryLink,
        categoryLink: categoryLink,
        productLink: productLink,
      })
      .subscribe(
        (res: any) => {
          if (res.isSuccess) {
            this.subCategory = res.data;
            if (this.subCategory.metaTitle) {
              this.updateMetaTags(
                this.subCategory.metaTitle,
                this.subCategory.metaDescription,
                this.subCategory.metaKeyword
              );
            }
            const data = {
              subCategoryName: this.subCategory.subCategoryName,
              subCategoryLink: this.subCategory.subCategoryLink,
              metaTitle: this.subCategory.metaTitle,
              metaDescription: this.subCategory.metaDescription,
              metaKeyword: this.subCategory.metaKeyword,
            };
            this.dataService.updateData('subCategoryData', data);
            this.scrollToSection1();
          } else {
            this.toastr.error('Failed to fetch product details');
          }
        },
        (err) => {
          this.toastr.error(
            err.error?.message || 'Error fetching product details'
          );
        }
      );
  }

  updateMetaTags(title: string, description: string, keywords: string): void {
    // Set the document title
    this.titleService.setTitle(title);

    // Update meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  scrollToSection1(): void {
    if (this.scrollTarget1) {
      this.scrollToElement1(this.scrollTarget1.nativeElement);
    }
  }

  scrollToElement1(target: HTMLElement): void {
    if (isPlatformBrowser(this.platformId1)) {
      const targetOffsetTop1 = target.offsetTop - 50;
      window.scrollTo({
        top: targetOffsetTop1,
        behavior: 'smooth',
      });
    }
  }
}
