import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { HttpService } from '../http.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DataSharingService } from '../data-sharing.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-category-details',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterOutlet],
  templateUrl: './category-details.component.html',
  styleUrl: './category-details.component.css',
})
export class CategoryDetailsComponent implements OnInit {
  @ViewChild('scrollTarget', { static: false }) scrollTarget!: ElementRef;
  @ViewChild('scrollTarget1', { static: false }) scrollTarget1!: ElementRef;
  productLink!: string;
  categoryLink!: string;
  category: any = {};
  subCategory: any = {};

  public isExpanded: boolean = false;
  public isSubCategoryExpanded: boolean = false;

  get categoryDescription(): string {
    if (this.isExpanded || !this.category.description) {
      return this.category.description;
    }
    return (
      this.category.description.slice(0, 375) +
      (this.category.description.length > 375 ? '...' : '')
    );
  }

  get subCategoryDescription(): string {
    if (this.isSubCategoryExpanded || !this.subCategory.description) {
      return this.subCategory.description;
    }
    return (
      this.subCategory.description.slice(0, 375) +
      (this.subCategory.description.length > 375 ? '...' : '')
    );
  }

  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }

  toggleSubCategoryReadMore() {
    this.isSubCategoryExpanded = !this.isSubCategoryExpanded;
  }

  get showReadMoreButton(): boolean {
    // Show the button only if the description is longer than 375 characters
    return this.category.description && this.category.description.length > 375;
  }

  get showSubCategoryReadMoreButton(): boolean {
    // Show the button only if the description is longer than 375 characters
    return (
      this.subCategory.description && this.subCategory.description.length > 375
    );
  }

  constructor(
    public titleService: Title, // Inject Title service for setting title
    public metaService: Meta, // Inject Meta service for setting meta tags,
    public httpService: HttpService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(PLATFORM_ID) private platformId1: Object,
    private dataService: DataSharingService,
    public router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.categoryLink = params.get('categoryLink') || '';
      this.productLink =
        this.route.parent?.snapshot.paramMap.get('productLink') || '';
      this.fetchCategoryDetails(this.productLink, this.categoryLink);
    });
  }

  fetchCategoryDetails(productLink: string, categoryLink: string) {
    this.httpService
      .PostAPI('productCategory/getCategoryByUrl', {
        categoryLink: categoryLink,
        productLink: productLink,
      })
      .subscribe(
        (res: any) => {
          if (res.isSuccess) {
            this.category = res.data;
            if (this.category.metaTitle) {
              this.updateMetaTags(
                this.category.metaTitle,
                this.category.metaDescription,
                this.category.metaKeyword
              );
            }
            const data = {
              categoryName: this.category.categoryName,
              categoryLink: this.category.categoryLink,
              metaTitle: this.category.metaTitle,
              metaDescription: this.category.metaDescription,
              metaKeyword: this.category.metaKeyword,
            };
            this.dataService.updateData('categoryData', data);
            this.scrollToSection();
          } else {
            this.fetchSubCategoryDetails(productLink, categoryLink);
          }
        },
        (err) => {
          this.fetchSubCategoryDetails(productLink, categoryLink);
        }
      );
  }

  fetchSubCategoryDetails(productLink: string, categoryLink: string) {
    this.httpService
      .PostAPI('productSubCategory/getSubCategoryByUrl', {
        subCategoryLink: categoryLink,
        productLink: productLink,
      })
      .subscribe(
        (res: any) => {
          if (res.isSuccess) {
            this.category = {};
            this.subCategory = res.data;
            if (this.subCategory.metaTitle) {
              this.updateMetaTags(
                this.subCategory.metaTitle,
                this.subCategory.metaDescription,
                this.subCategory.metaKeyword
              );
            }
            const data = {
              subCategoryName: this.subCategory.subCategoryName,
              subCategoryLink: this.subCategory.subCategoryLink,
              metaTitle: this.subCategory.metaTitle,
              metaDescription: this.subCategory.metaDescription,
              metaKeyword: this.subCategory.metaKeyword,
            };
            this.dataService.updateData('subCategoryData', data);
            this.scrollToSection1();
          } else {
            this.router.navigateByUrl('/redirect-page');
          }
        },
        (err) => {
          this.router.navigateByUrl('/redirect-page');
        }
      );
  }

  updateMetaTags(title: string, description: string, keywords: string): void {
    // Set the document title
    this.titleService.setTitle(title);
    // Update meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  scrollToSection(): void {
    if (this.scrollTarget) {
      this.scrollToElement(this.scrollTarget.nativeElement);
    }
  }

  scrollToElement(target: HTMLElement): void {
    if (isPlatformBrowser(this.platformId)) {
      const targetOffsetTop = target.offsetTop - 50;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth',
      });
    }
  }

  scrollToSection1(): void {
    if (this.scrollTarget1) {
      this.scrollToElement1(this.scrollTarget1.nativeElement);
    }
  }

  scrollToElement1(target: HTMLElement): void {
    if (isPlatformBrowser(this.platformId1)) {
      const targetOffsetTop1 = target.offsetTop - 50;
      window.scrollTo({
        top: targetOffsetTop1,
        behavior: 'smooth',
      });
    }
  }
}
